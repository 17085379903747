import { ObjectType } from "__utils/types";
import * as actionTypes from "./actionTypes";
/**
 * Get Workflow chart
 */

export type IGetWorkflowChart = {
	readonly workflowChartData: ObjectType;
	readonly gettingWorkflowChartData: boolean;
	readonly getWorkflowChartDataSuccess: boolean;
	readonly getWorkflowChartDataFailure: boolean;
};

export const getWorkflowChartDefaultValue: IGetWorkflowChart = {
	workflowChartData: {},
	gettingWorkflowChartData: false,
	getWorkflowChartDataSuccess: false,
	getWorkflowChartDataFailure: false,
};

export type IGetWorkflowChartAction = {
	type: typeof actionTypes.GET_WORKFLOW_CHART_DATA;
};

export type IGetWorkflowChartSuccessAction = {
	type: typeof actionTypes.GET_WORKFLOW_CHART_DATA_SUCCESS;
};

export type IGetWorkflowChartFailureAction = {
	type: typeof actionTypes.GET_WORKFLOW_CHART_DATA_FAILURE;
};

export type IGetWorkflowChartResetAction = {
	type: typeof actionTypes.GET_WORKFLOW_CHART_DATA_RESET;
};

export type IGetWorkflowChartActions =
	| IGetWorkflowChartAction
	| IGetWorkflowChartSuccessAction
	| IGetWorkflowChartFailureAction
	| IGetWorkflowChartResetAction;
