import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { DownloadInvoicePayloadType, GetInvoicesPayloadType, IUpdateInvoicePayload } from "../__redux/types";
import { DOWNLOAD_INVOICE, GET_INVOICES, UPDATE_INVOICE } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getInvoicesApi = async ({ id }: GetInvoicesPayloadType): Promise<AxiosResponse<any>> =>
	axios.get(`${GET_INVOICES}${id}/`).catch(errorHandler);

export const downloadInvoiceApi = async ({
	id,
	paymentId,
}: DownloadInvoicePayloadType): Promise<AxiosResponse<any>> => {
	const url: string = DOWNLOAD_INVOICE.replace(":id", `${id}`).replace(":paymentId", `${paymentId}`);

	return axios.get(url, { responseType: "blob", timeout: 30000 }).catch(errorHandler);
};

export const updateInvoiceApi = async ({
	id,
	paymentId,
	...rest
}: IUpdateInvoicePayload): Promise<AxiosResponse<any>> => {
	const url = UPDATE_INVOICE.replace(":id", `${id}`).replace(":paymentId", `${paymentId}`);
	return axios.patch(url, rest).catch(errorHandler);
};
