export const ADD_DOC_REQUEST = "ADD_DOC_REQUEST";
export const ADD_DOC_REQUEST_SUCCESS = "ADD_DOC_REQUEST_SUCCESS";
export const ADD_DOC_REQUEST_FAILURE = "ADD_DOC_REQUEST_FAILURE";
export const ADD_DOC_REQUEST_RESET = "ADD_DOC_REQUEST_RESET";

export const EDIT_DOC_REQUEST = "EDIT_DOC_REQUEST";
export const EDIT_DOC_REQUEST_SUCCESS = "EDIT_DOC_REQUEST_SUCCESS";
export const EDIT_DOC_REQUEST_FAILURE = "EDIT_DOC_REQUEST_FAILURE";
export const EDIT_DOC_REQUEST_RESET = "EDIT_DOC_REQUEST_RESET";

export const GET_DOC_REQUEST_DETAILS = "GET_DOC_REQUEST_DETAILS";
export const GET_DOC_REQUEST_DETAILS_SUCCESS = "GET_DOC_REQUEST_DETAILS_SUCCESS";
export const GET_DOC_REQUEST_DETAILS_FAILURE = "GET_DOC_REQUEST_DETAILS_FAILURE";
export const GET_DOC_REQUEST_DETAILS_RESET = "GET_DOC_REQUEST_DETAILS_RESET";

export const GET_SIGNING_REQUEST_DETAILS = "GET_SIGNING_REQUEST_DETAILS";
export const GET_SIGNING_REQUEST_DETAILS_SUCCESS = "GET_SIGNING_REQUEST_DETAILS_SUCCESS";
export const GET_SIGNING_REQUEST_DETAILS_FAILURE = "GET_SIGNING_REQUEST_DETAILS_FAILURE";
export const GET_SIGNING_REQUEST_DETAILS_RESET = "GET_SIGNING_REQUEST_DETAILS_RESET";

export const GET_DOC_REQUESTS = "GET_DOC_REQUESTS";
export const GET_DOC_REQUESTS_SUCCESS = "GET_DOC_REQUESTS_SUCCESS";
export const GET_DOC_REQUESTS_FAILURE = "GET_DOC_REQUESTS_FAILURE";
export const GET_DOC_REQUESTS_RESET = "GET_DOC_REQUESTS_RESET";

export const GET_DOC_REQUEST_DOCUMENT = "GET_DOC_REQUEST_DOCUMENT";
export const GET_DOC_REQUEST_DOCUMENT_SUCCESS = "GET_DOC_REQUEST_DOCUMENT_SUCCESS";
export const GET_DOC_REQUEST_DOCUMENT_FAILURE = "GET_DOC_REQUEST_DOCUMENT_FAILURE";
export const GET_DOC_REQUEST_DOCUMENT_RESET = "GET_DOC_REQUEST_DOCUMENT_RESET";

export const COMPLETE_DOC_REQUEST_DOCUMENT = "COMPLETE_DOC_REQUEST_DOCUMENT";
export const COMPLETE_DOC_REQUEST_DOCUMENT_SUCCESS = "COMPLETE_DOC_REQUEST_DOCUMENT_SUCCESS";
export const COMPLETE_DOC_REQUEST_DOCUMENT_FAILURE = "COMPLETE_DOC_REQUEST_DOCUMENT_FAILURE";
export const COMPLETE_DOC_REQUEST_DOCUMENT_RESET = "COMPLETE_DOC_REQUEST_DOCUMENT_RESET";

export const COMPLETE_DOC_REQUEST = "COMPLETE_DOC_REQUEST";
export const COMPLETE_DOC_REQUEST_SUCCESS = "COMPLETE_DOC_REQUEST_SUCCESS";
export const COMPLETE_DOC_REQUEST_FAILURE = "COMPLETE_DOC_REQUEST_FAILURE";
export const COMPLETE_DOC_REQUEST_RESET = "COMPLETE_DOC_REQUEST_RESET";

export const MOVE_DOC_REQUEST_STATE = "MOVE_DOC_REQUEST_STATE";
export const MOVE_DOC_REQUEST_STATE_SUCCESS = "MOVE_DOC_REQUEST_STATE_SUCCESS";
export const MOVE_DOC_REQUEST_STATE_FAILURE = "MOVE_DOC_REQUEST_STATE_FAILURE";
export const MOVE_DOC_REQUEST_STATE_RESET = "MOVE_DOC_REQUEST_STATE_RESET";

export const UPDATE_DOC_REQUEST_DOCUMENT_FILES = "UPDATE_DOC_REQUEST_DOCUMENT_FILES";

export const GET_DOC_REQUESTS_ON_SCROLL = "GET_DOC_REQUESTS_ON_SCROLL";
export const GET_DOC_REQUESTS_ON_SCROLL_SUCCESS = "GET_DOC_REQUESTS_ON_SCROLL_SUCCESS";

export const FILTER_DOC_REQUESTS = "FILTER_DOC_REQUESTS";
export const FILTER_DOC_REQUESTS_RESET = "FILTER_DOC_REQUESTS_RESET";

// Remove File
export const REMOVE_DOCUMENT_FILE = "REMOVE_DOCUMENT_FILE";
export const REMOVE_DOCUMENT_FILE_SUCCESS = "REMOVE_DOCUMENT_FILE_SUCCESS";
export const REMOVE_DOCUMENT_FILE_FAILURE = "REMOVE_DOCUMENT_FILE_FAILURE";
export const REMOVE_DOCUMENT_FILE_RESET = "REMOVE_DOCUMENT_FILE_RESET";

// Remind
export const REMIND_DOC_REQUEST = "REMIND_DOC_REQUEST";
export const REMIND_DOC_REQUEST_SUCCESS = "REMIND_DOC_REQUEST_SUCCESS";
export const REMIND_DOC_REQUEST_FAILURE = "REMIND_DOC_REQUEST_FAILURE";
export const REMIND_DOC_REQUEST_RESET = "REMIND_DOC_REQUEST_RESET";

// Remove activity
export const REMOVE_DOCUMENT_ACTIVITY = "REMOVE_DOCUMENT_ACTIVITY";
export const REMOVE_DOCUMENT_ACTIVITY_SUCCESS = "REMOVE_DOCUMENT_ACTIVITY_SUCCESS";
export const REMOVE_DOCUMENT_ACTIVITY_FAILURE = "REMOVE_DOCUMENT_ACTIVITY_FAILURE";
export const REMOVE_DOCUMENT_ACTIVITY_RESET = "REMOVE_DOCUMENT_ACTIVITY_RESET";

// delete sign docs
export const DELETE_SIGNED_DOCUMENT = "DELETE_SIGNED_DOCUMENT";
export const DELETE_SIGNED_DOCUMENT_SUCCESS = "DELETE_SIGNED_DOCUMENT_SUCCESS";
export const DELETE_SIGNED_DOCUMENT_FAILURE = "DELETE_SIGNED_DOCUMENT_FAILURE";
export const DELETE_SIGNED_DOCUMENT_RESET = "DELETE_SIGNED_DOCUMENT_RESET";
