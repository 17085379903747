import { DocumentDetailsType, DocumentsType } from "__pages/RequestDocuments/__redux/types";
import { ObjectType, ResponseGeneratorType } from "__utils/types";
import { call, put, select, takeLatest } from "redux-saga/effects";

import dayjs from "dayjs";
import { search } from "__utils/helpers";
import { DocRequestsType, DocSigningRequestDetailsType, GetDocRequestDetailsType, GetDocRequestsType } from "./types";
import { getDocRequestDetailsSlr, getDocRequestsSlr } from "./selectors";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { compositeId } from "../__utils/helpers";

const getDocRequests = function* (action: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getDocRequestsApi, action.payload);
		const { status, data } = response;

		if (status === 200) {
			const { limit }: GetDocRequestsType = yield select(getDocRequestsSlr);
			const numOfPages: number = Math.ceil(data.length / limit);
			const withCompositeId = compositeId(data);
			const partial: DocRequestsType = withCompositeId.slice(0, 1 * limit);

			yield put({
				type: actionTypes.GET_DOC_REQUESTS_SUCCESS,
				docRequests: withCompositeId,
				partial,
				numOfPages,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_DOC_REQUESTS_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_DOC_REQUESTS_FAILURE });
	}
};

export const getDocRequestsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_DOC_REQUESTS, getDocRequests);
};

const getDocRequestDetails = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getDocRequestDetailsApi, payload);
		const { status, data } = response;

		if (status === 200) {
			const { fetchedDocRequestDetails }: GetDocRequestDetailsType = yield select(getDocRequestDetailsSlr);

			yield put({
				type: actionTypes.GET_DOC_REQUEST_DETAILS_SUCCESS,
				docRequestDetails: data,
				fetchedDocRequestDetails: [...fetchedDocRequestDetails, data.id],
			});

			// Plug in this request to the request list by replacing the existing request
			const { partial }: GetDocRequestsType = yield select(getDocRequestsSlr);
			const _partial: DocRequestsType = partial.reduce(
				(acc: DocRequestsType, curr: DocSigningRequestDetailsType) => {
					if (data.id === curr.id) {
						acc.push({ ...data, compositeId: curr.compositeId });
					} else {
						acc.push(curr);
					}
					return acc;
				},
				[]
			);

			yield put({
				type: actionTypes.GET_DOC_REQUESTS_SUCCESS,
				partial: _partial,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_DOC_REQUEST_DETAILS_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_DOC_REQUEST_DETAILS_FAILURE });
	}
};

export const getDocRequestDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_DOC_REQUEST_DETAILS, getDocRequestDetails);
};

const getSigningRequestDetails = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getSigningRequestDetailsApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_SIGNING_REQUEST_DETAILS_SUCCESS,
			});

			// Plug in this request to the request list by replacing the existing request
			const { partial }: GetDocRequestsType = yield select(getDocRequestsSlr);
			const _partial: DocRequestsType = partial.reduce(
				(acc: DocRequestsType, curr: DocSigningRequestDetailsType) => {
					if (data.envelopeId === curr.envelopeId) {
						acc.push({ ...curr, signers: data.signers });
					} else {
						acc.push(curr);
					}
					return acc;
				},
				[]
			);

			yield put({
				type: actionTypes.GET_DOC_REQUESTS_SUCCESS,
				partial: _partial,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_SIGNING_REQUEST_DETAILS_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_SIGNING_REQUEST_DETAILS_FAILURE });
	}
};

export const getSigningRequestDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_SIGNING_REQUEST_DETAILS, getSigningRequestDetails);
};

const addDocRequest = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.addDocRequestApi, payload);
		const { status, data } = response;

		if (status === 201) {
			yield put({
				type: actionTypes.ADD_DOC_REQUEST_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.ADD_DOC_REQUEST_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.ADD_DOC_REQUEST_FAILURE });
	}
};

export const addDocRequestSaga = function* (): any {
	yield takeLatest(actionTypes.ADD_DOC_REQUEST, addDocRequest);
};

const editDocRequest = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.editDocRequestApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.EDIT_DOC_REQUEST_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.EDIT_DOC_REQUEST_FAILURE, editDocRequestError: data.errors });
		}
	} catch (error) {
		yield put({ type: actionTypes.EDIT_DOC_REQUEST_FAILURE });
	}
};

export const editDocRequestSaga = function* (): any {
	yield takeLatest(actionTypes.EDIT_DOC_REQUEST, editDocRequest);
};

const getDocRequestDocument = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getDocRequestDocumentApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_DOC_REQUEST_DOCUMENT_SUCCESS,
				docRequestDocument: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_DOC_REQUEST_DOCUMENT_FAILURE, error: data });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_DOC_REQUEST_DOCUMENT_FAILURE });
	}
};

export const getDocRequestDocumentSaga = function* (): any {
	yield takeLatest(actionTypes.GET_DOC_REQUEST_DOCUMENT, getDocRequestDocument);
};

const completeDocRequestDocument = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.completeDocRequestDocumentApi, payload);
		const { status } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.COMPLETE_DOC_REQUEST_DOCUMENT_SUCCESS,
			});
			// Update doc to complete
			const { documentId, requestId, isComplete } = payload;
			const { docRequests, filtered, partial }: GetDocRequestsType = yield select(getDocRequestsSlr);
			const updateCompleteStatus = (requests: DocRequestsType): DocRequestsType =>
				requests.map((docRequest: DocSigningRequestDetailsType) => {
					if (requestId === docRequest.id) {
						const docs: DocumentDetailsType[] = docRequest.documents.map((doc: DocumentDetailsType) => {
							if (doc.id !== documentId) {
								return doc;
							}
							return { ...doc, isComplete };
						});
						return { ...docRequest, documents: docs };
					}
					return docRequest;
				});
			const _docRequests: DocRequestsType = updateCompleteStatus(docRequests);
			const _filtered: DocRequestsType = updateCompleteStatus(filtered);
			const _partial: DocRequestsType = updateCompleteStatus(partial);
			yield put({
				type: actionTypes.GET_DOC_REQUESTS_SUCCESS,
				partial: _partial,
				filtered: _filtered,
				docRequests: _docRequests,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.COMPLETE_DOC_REQUEST_DOCUMENT_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.COMPLETE_DOC_REQUEST_DOCUMENT_FAILURE });
	}
};

export const completeDocRequestDocumentSaga = function* (): any {
	yield takeLatest(actionTypes.COMPLETE_DOC_REQUEST_DOCUMENT, completeDocRequestDocument);
};

const updateDocRequestDocumentFiles = function* ({ payload }: any): any {
	try {
		const { docId, requestId, files } = payload;
		const { partial }: GetDocRequestsType = yield select(getDocRequestsSlr);
		const _partial: DocRequestsType = partial.map((request: DocSigningRequestDetailsType) => {
			const documents: DocumentsType = request.documents.map((doc: DocumentDetailsType) => {
				if (doc.id === docId) {
					return { ...doc, files };
				}
				return doc;
			});
			if (request.id === requestId) {
				return { ...request, documents };
			}
			return request;
		});

		yield put({
			type: actionTypes.GET_DOC_REQUESTS_SUCCESS,
			partial: _partial,
		});
	} catch (error) {
		yield put({
			type: actionTypes.GET_DOC_REQUESTS_FAILURE,
		});
	}
};

export const updateDocRequestDocumentFilesSaga = function* (): any {
	yield takeLatest(actionTypes.UPDATE_DOC_REQUEST_DOCUMENT_FILES, updateDocRequestDocumentFiles);
};

const moveDocRequestState = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.moveDocRequestStateApi, payload);
		const { status, data } = response;

		if (status === 200) {
			const { requestId, state } = payload;
			const { docRequests, currPage, limit }: GetDocRequestsType = yield select(getDocRequestsSlr);
			let _docRequests: DocRequestsType;
			if (state !== "Delete") {
				_docRequests = docRequests.map((item: DocSigningRequestDetailsType) => {
					if (item.id === requestId) {
						return { ...item, state, movements: data.movements };
					}
					return item;
				});
			} else {
				_docRequests = docRequests.reduce((acc: DocRequestsType, curr: DocSigningRequestDetailsType) => {
					if (curr.id !== requestId) {
						acc.push(curr);
					}
					return acc;
				}, []);
			}
			const partial: DocRequestsType = _docRequests!.slice(0, currPage * limit);

			yield put({
				type: actionTypes.MOVE_DOC_REQUEST_STATE_SUCCESS,
			});

			yield put({
				type: actionTypes.GET_DOC_REQUESTS_SUCCESS,
				partial,
				docRequests: _docRequests!,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.MOVE_DOC_REQUEST_STATE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.MOVE_DOC_REQUEST_STATE_FAILURE });
	}
};

export const moveDocRequestStateSaga = function* (): any {
	yield takeLatest(actionTypes.MOVE_DOC_REQUEST_STATE, moveDocRequestState);
};

const filterDocRequests = function* ({ payload }: any): any {
	try {
		const { filters } = payload;
		const { docRequests, currPage, limit }: GetDocRequestsType = yield select(getDocRequestsSlr);

		const result: DocRequestsType = search(docRequests, filters.searchFilter);
		const _docRequests: DocRequestsType = filters.searchFilter ? result : docRequests;
		const partial: DocRequestsType = _docRequests.slice(0, currPage * limit);

		yield put({
			type: actionTypes.GET_DOC_REQUESTS_SUCCESS,
			partial,
		});
	} catch (error) {
		yield put({
			type: actionTypes.GET_DOC_REQUESTS_FAILURE,
		});
	}
};

export const filterDocRequestsSaga = function* (): any {
	yield takeLatest(actionTypes.FILTER_DOC_REQUESTS, filterDocRequests);
};

const getDocRequestsOnScroll = function* ({ payload }: any): any {
	try {
		const { page } = payload;
		const { docRequests, numOfPages, limit }: GetDocRequestsType = yield select(getDocRequestsSlr);

		if (page <= numOfPages) {
			const partial: DocRequestsType = docRequests.slice(0, page * limit);

			yield put({
				type: actionTypes.GET_DOC_REQUESTS_SUCCESS,
				partial,
				numOfPages,
				currPage: page,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.GET_DOC_REQUESTS_FAILURE,
		});
	}
};

export const getDocRequestsOnScrollSaga = function* (): any {
	yield takeLatest(actionTypes.GET_DOC_REQUESTS_ON_SCROLL, getDocRequestsOnScroll);
};

const removeDocsFile = function* ({ payload }: any): any {
	try {
		const { docId, key } = payload;
		const response: ResponseGeneratorType = yield call(api.removeDocFileApi, payload);
		const { partial, currPage, limit }: GetDocRequestsType = yield select(getDocRequestsSlr);
		const { status } = response;

		if (status === 200) {
			const _docRequests: DocRequestsType = partial.map((request: DocSigningRequestDetailsType) => {
				const documents: DocumentsType = request.documents.map((doc: DocumentDetailsType) => {
					if (doc.id === docId) {
						const filteredFile = doc.files.filter((file: ObjectType) => file.key !== key);
						return { ...doc, files: filteredFile };
					}
					return doc;
				});
				return { ...request, documents };
			});

			const _partial: DocRequestsType = _docRequests.slice(0, currPage * limit);

			yield put({
				type: actionTypes.GET_DOC_REQUESTS_SUCCESS,
				partial: _partial,
			});

			yield put({
				type: actionTypes.REMOVE_DOCUMENT_FILE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.REMOVE_DOCUMENT_FILE_FAILURE,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.REMOVE_DOCUMENT_FILE_FAILURE,
		});
	}
};

export const removeDocsFileSaga = function* (): any {
	yield takeLatest(actionTypes.REMOVE_DOCUMENT_FILE, removeDocsFile);
};

const remindDocRequest = function* ({ payload }: any): any {
	try {
		const { status }: ResponseGeneratorType = yield call(api.remindDocRequestApi, payload);

		if (status === 200) {
			const { requestId } = payload;
			const { partial, currPage, limit }: GetDocRequestsType = yield select(getDocRequestsSlr);

			const _docRequests: DocRequestsType = partial.map((request: DocSigningRequestDetailsType) => {
				if (request.id === requestId) {
					return { ...request, reminders: [dayjs().toISOString(), ...request.reminders] };
				}
				return request;
			});

			const _partial: DocRequestsType = _docRequests.slice(0, currPage * limit);

			yield put({
				type: actionTypes.GET_DOC_REQUESTS_SUCCESS,
				partial: _partial,
			});

			yield put({
				type: actionTypes.REMIND_DOC_REQUEST_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.REMIND_DOC_REQUEST_FAILURE,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.REMIND_DOC_REQUEST_FAILURE,
		});
	}
};

export const remindDocRequestSaga = function* (): any {
	yield takeLatest(actionTypes.REMIND_DOC_REQUEST, remindDocRequest);
};

const removeDocumentActivity = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.removeDocActivityApi, payload);
		const { status } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.REMOVE_DOCUMENT_ACTIVITY_SUCCESS,
			});

			// Update all requests
			const { requestId, documentId } = payload;
			const { docRequests, filtered, partial }: GetDocRequestsType = yield select(getDocRequestsSlr);

			const updateActivity = (requests: DocRequestsType): DocRequestsType =>
				requests.map((docRequest: DocSigningRequestDetailsType) => {
					if (requestId === docRequest.id) {
						const docs: DocumentDetailsType[] = docRequest.documents.map((doc: DocumentDetailsType) => {
							if (doc.id !== documentId) {
								return doc;
							}
							return { ...doc, activity: false };
						});
						return { ...docRequest, documents: docs };
					}
					return docRequest;
				});

			const _docRequests: DocRequestsType = updateActivity(docRequests);
			const _filtered: DocRequestsType = updateActivity(filtered);
			const _partial: DocRequestsType = updateActivity(partial);

			yield put({
				type: actionTypes.GET_DOC_REQUESTS_SUCCESS,
				docRequests: _docRequests,
				filtered: _filtered,
				partial: _partial,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.REMOVE_DOCUMENT_ACTIVITY_FAILURE,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.REMOVE_DOCUMENT_ACTIVITY_FAILURE,
		});
	}
};

export const removeDocumentActivitySaga = function* (): any {
	yield takeLatest(actionTypes.REMOVE_DOCUMENT_ACTIVITY, removeDocumentActivity);
};

const deleteSignedDoc = function* ({ payload }: any): any {
	try {
		const { envelopeId } = payload;
		const response: ResponseGeneratorType = yield call(api.removeDocReqApi, payload);

		const { status } = response;

		if (status === 204) {
			const { partial, docRequests, ...rest }: GetDocRequestsType = yield select(getDocRequestsSlr);
			const filteredPartial = partial.filter((obj) => obj.envelopeId !== envelopeId);
			const filteredDocRequests = docRequests.filter((obj) => obj.envelopeId !== envelopeId);

			yield put({
				type: actionTypes.DELETE_SIGNED_DOCUMENT_SUCCESS,
			});

			yield put({
				type: actionTypes.GET_DOC_REQUESTS_SUCCESS,
				...rest,
				docRequests: filteredDocRequests,
				partial: filteredPartial,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.DELETE_SIGNED_DOCUMENT_FAILURE,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.DELETE_SIGNED_DOCUMENT_FAILURE,
		});
	}
};

export const deleteSignedDocSaga = function* (): any {
	yield takeLatest(actionTypes.DELETE_SIGNED_DOCUMENT, deleteSignedDoc);
};
