import { errorHandler, getAxiosInstance } from "__utils/helpers";

import { API_ROOT } from "__utils/constants";
import { AxiosInstance, AxiosResponse } from "axios";
import {
	FAVOURITE_FUND,
	FUNDS,
	GET_REQUEST_SUMMARY,
	GET_SMSF_COMPLYING_FUNDS_COUNT,
	GET_FUND_USER_META,
	GET_TAGS,
	DEACTIVATE_ACCOUNT,
	DELETE_ACCOUNT,
	RESTORE_ACCOUNT,
	ARCHIVE_ACCOUNT,
	DOWNLOAD_CLIENT_LIST,
} from "./constants";
import {
	ArchiveFundPayloadType,
	DeleteFundPayloadType,
	FavouriteFundPayloadType,
	RestoreFundPayloadType,
	DeactivateFundPayloadType,
} from "../__redux/types";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getFundsApi = async (): Promise<AxiosResponse<any>> => axios.get(FUNDS).catch(errorHandler);

export const favouriteFundApi = async (payload: FavouriteFundPayloadType): Promise<AxiosResponse<any>> =>
	axios.post(FAVOURITE_FUND, payload).catch(errorHandler);

export const getRequestSummaryApi = async (): Promise<AxiosResponse<any>> =>
	axios.get(GET_REQUEST_SUMMARY).catch(errorHandler);

export const getSMSFComplyingFundsCountApi = async (): Promise<AxiosResponse<any>> =>
	axios.get(GET_SMSF_COMPLYING_FUNDS_COUNT).catch(errorHandler);

export const getFundsUserMetaApi = async (): Promise<AxiosResponse<any>> =>
	axios.get(GET_FUND_USER_META).catch(errorHandler);

export const getTagsApi = async (): Promise<AxiosResponse<any>> => axios.get(GET_TAGS).catch(errorHandler);
export const deactivateFundDetailsApi = async ({ id }: DeactivateFundPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = DEACTIVATE_ACCOUNT.replace(":id", `${id}`);
	return axios.put(url).catch(errorHandler);
};

export const deleteFundApi = async ({ id }: DeleteFundPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = DELETE_ACCOUNT.replace(":id", `${id}`);
	return axios.put(url).catch(errorHandler);
};

export const restoreFundApi = async ({ id }: RestoreFundPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = RESTORE_ACCOUNT.replace(":id", `${id}`);
	return axios.put(url).catch(errorHandler);
};

export const archiveFundApi = async ({ id }: ArchiveFundPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = ARCHIVE_ACCOUNT.replace(":id", `${id}`);
	return axios.put(url).catch(errorHandler);
};

export const downloadClientListApi = async (): Promise<AxiosResponse<any>> =>
	axios.get(DOWNLOAD_CLIENT_LIST).catch(errorHandler);
