import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

import {
	AddDocRequestActionTypes,
	AddDocRequestType,
	CompleteDocRequestActionTypes,
	CompleteDocRequestDocumentActionTypes,
	CompleteDocRequestDocumentType,
	CompleteDocRequestType,
	EditDocRequestActionTypes,
	EditDocRequestType,
	GetDocRequestDetailsActionTypes,
	GetDocRequestDetailsType,
	GetDocRequestDocumentActionTypes,
	GetDocRequestDocumentType,
	GetDocRequestsActionTypes,
	GetDocRequestsType,
	GetSigningRequestDetailsActionTypes,
	GetSigningRequestDetailsType,
	MoveDocRequestStateActionTypes,
	MoveDocRequestStateType,
	RemindDocRequestActionTypes,
	RemindDocRequestType,
	RemoveDocFileActionTypes,
	RemoveDocFileType,
	RemoveDocumentActivityActionTypes,
	RemoveDocumentActivityType,
	addDocRequestDefaultValue,
	completeDocRequestDefaultValue,
	completeDocRequestDocumentDefaultValue,
	editDocRequestDefaultValue,
	getDocRequestDetailsDefaultValue,
	getDocRequestDocumentDefaultValue,
	getDocRequestsDefaultValue,
	getSigningRequestDetailsDefaultValue,
	moveDocRequestStateDefaultValue,
	remindDocRequestDefaultValue,
	removeDocFileDefaultValue,
	removeDocumentActivityDefaultValue,
	DeleteSignedDocumentType,
	deleteSignedDocumentTypeDefaultValue,
	DeleteSignedDocumentActionTypes,
} from "./types";

import { docuSignEnvelopesReducer } from "../RequestsToolBar/AddEnvelopModal/AddEnvelopeForm/__redux/reducers";

export const getDocRequestsReducer = (
	state = getDocRequestsDefaultValue,
	action: GetDocRequestsActionTypes
): GetDocRequestsType => {
	switch (action.type) {
		case actionTypes.GET_DOC_REQUESTS:
			return {
				...state,
				isGettingDocRequests: true,
			};
		case actionTypes.GET_DOC_REQUESTS_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isGetDocRequestsError: false,
				isGetDocRequestsSuccess: true,
				isGettingDocRequests: false,
				getDocRequestsError: {},
			};
		case actionTypes.GET_DOC_REQUESTS_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isGettingDocRequests: false,
				isGetDocRequestsError: true,
				isGetDocRequestsSuccess: false,
			};
		case actionTypes.GET_DOC_REQUESTS_RESET:
			return {
				...state,
				...getDocRequestsDefaultValue,
			};
		default:
			return state;
	}
};

export const getDocRequestDetailsReducer = (
	state = getDocRequestDetailsDefaultValue,
	action: GetDocRequestDetailsActionTypes
): GetDocRequestDetailsType => {
	switch (action.type) {
		case actionTypes.GET_DOC_REQUEST_DETAILS:
			return {
				...state,
				currRequestId: (action as any).payload.id,
				isGettingDocRequestDetails: true,
			};
		case actionTypes.GET_DOC_REQUEST_DETAILS_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isGetDocRequestDetailsError: false,
				isGetDocRequestDetailsSuccess: true,
				isGettingDocRequestDetails: false,
				getDocRequestDetailsError: {},
			};
		case actionTypes.GET_DOC_REQUEST_DETAILS_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isGettingDocRequestDetails: false,
				isGetDocRequestDetailsError: true,
				isGetDocRequestDetailsSuccess: false,
			};
		case actionTypes.GET_DOC_REQUEST_DETAILS_RESET:
			return {
				...state,
				...getDocRequestDetailsDefaultValue,
			};
		default:
			return state;
	}
};

export const getSigningRequestDetailsReducer = (
	state = getSigningRequestDetailsDefaultValue,
	action: GetSigningRequestDetailsActionTypes
): GetSigningRequestDetailsType => {
	switch (action.type) {
		case actionTypes.GET_SIGNING_REQUEST_DETAILS:
			return {
				...state,
				currEnvelopeId: (action as any).payload.envelopeId,
				isGettingSigningRequestDetails: true,
			};
		case actionTypes.GET_SIGNING_REQUEST_DETAILS_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isGetSigningRequestDetailsFailure: false,
				isGetSigningRequestDetailsSuccess: true,
				isGettingSigningRequestDetails: false,
				getSigningRequestDetailsError: {},
			};
		case actionTypes.GET_SIGNING_REQUEST_DETAILS_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isGettingSigningRequestDetails: false,
				isGetSigningRequestDetailsFailure: true,
				isGetSigningRequestDetailsSuccess: false,
			};
		case actionTypes.GET_SIGNING_REQUEST_DETAILS_RESET:
			return {
				...state,
				...getSigningRequestDetailsDefaultValue,
			};
		default:
			return state;
	}
};

export const addDocRequestReducer = (
	state = addDocRequestDefaultValue,
	action: AddDocRequestActionTypes
): AddDocRequestType => {
	switch (action.type) {
		case actionTypes.ADD_DOC_REQUEST:
			return {
				...state,
				isAddingDocRequest: true,
			};
		case actionTypes.ADD_DOC_REQUEST_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isAddDocRequestError: false,
				isAddDocRequestSuccess: true,
				isAddingDocRequest: false,
				addDocRequestError: {},
			};
		case actionTypes.ADD_DOC_REQUEST_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isAddingDocRequest: false,
				isAddDocRequestError: true,
				isAddDocRequestSuccess: false,
			};
		case actionTypes.ADD_DOC_REQUEST_RESET:
			return {
				...state,
				...addDocRequestDefaultValue,
			};
		default:
			return state;
	}
};

export const editDocRequestReducer = (
	state = editDocRequestDefaultValue,
	action: EditDocRequestActionTypes
): EditDocRequestType => {
	switch (action.type) {
		case actionTypes.EDIT_DOC_REQUEST:
			return {
				...state,
				action: action.payload.action.toLowerCase(),
				isEditingDocRequest: true,
			};
		case actionTypes.EDIT_DOC_REQUEST_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isEditDocRequestError: false,
				isEditDocRequestSuccess: true,
				isEditingDocRequest: false,
				editDocRequestError: {},
			};
		case actionTypes.EDIT_DOC_REQUEST_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isEditingDocRequest: false,
				isEditDocRequestError: true,
				isEditDocRequestSuccess: false,
			};
		case actionTypes.EDIT_DOC_REQUEST_RESET:
			return {
				...state,
				...editDocRequestDefaultValue,
			};
		default:
			return state;
	}
};

export const getDocRequestDocumentReducer = (
	state = getDocRequestDocumentDefaultValue,
	action: GetDocRequestDocumentActionTypes
): GetDocRequestDocumentType => {
	switch (action.type) {
		case actionTypes.GET_DOC_REQUEST_DOCUMENT:
			return {
				...state,
				isGettingDocRequestDocument: true,
			};
		case actionTypes.GET_DOC_REQUEST_DOCUMENT_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isGetDocRequestDocumentError: false,
				isGetDocRequestDocumentSuccess: true,
				isGettingDocRequestDocument: false,
				getDocRequestDocumentError: {},
			};
		case actionTypes.GET_DOC_REQUEST_DOCUMENT_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isGettingDocRequestDocument: false,
				isGetDocRequestDocumentError: true,
				isGetDocRequestDocumentSuccess: false,
			};
		case actionTypes.GET_DOC_REQUEST_DOCUMENT_RESET:
			return {
				...state,
				...getDocRequestDetailsDefaultValue,
			};
		default:
			return state;
	}
};

export const completeDocRequestDocumentReducer = (
	state = completeDocRequestDocumentDefaultValue,
	action: CompleteDocRequestDocumentActionTypes
): CompleteDocRequestDocumentType => {
	switch (action.type) {
		case actionTypes.COMPLETE_DOC_REQUEST_DOCUMENT:
			return {
				...state,
				isComplete: (action as any).payload.isComplete,
				documentId: (action as any).payload.documentId,
				requestId: (action as any).payload.requestId,
				isCompletingDocRequestDocument: true,
			};
		case actionTypes.COMPLETE_DOC_REQUEST_DOCUMENT_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isCompleteDocRequestDocumentError: false,
				isCompleteDocRequestDocumentSuccess: true,
				isCompletingDocRequestDocument: false,
			};
		case actionTypes.COMPLETE_DOC_REQUEST_DOCUMENT_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isCompletingDocRequestDocument: false,
				isCompleteDocRequestDocumentError: true,
				isCompleteDocRequestDocumentSuccess: false,
			};
		case actionTypes.COMPLETE_DOC_REQUEST_DOCUMENT_RESET:
			return {
				...state,
				...completeDocRequestDocumentDefaultValue,
			};
		default:
			return state;
	}
};

// DEPRECATED
export const completeDocRequestReducer = (
	state = completeDocRequestDefaultValue,
	action: CompleteDocRequestActionTypes
): CompleteDocRequestType => {
	switch (action.type) {
		case actionTypes.COMPLETE_DOC_REQUEST:
			return {
				...state,
				isCompletingDocRequest: true,
			};
		case actionTypes.COMPLETE_DOC_REQUEST_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isCompleteDocRequestError: false,
				isCompleteDocRequestSuccess: true,
				isCompletingDocRequest: false,
			};
		case actionTypes.COMPLETE_DOC_REQUEST_FAILURE:
			const { type: errorType, ...errorRest } = action;
			return {
				...state,
				...errorRest,
				isCompletingDocRequest: false,
				isCompleteDocRequestError: true,
				isCompleteDocRequestSuccess: false,
			};
		case actionTypes.COMPLETE_DOC_REQUEST_RESET:
			return {
				...state,
				...completeDocRequestDefaultValue,
			};
		default:
			return state;
	}
};

export const moveDocRequestStateReducer = (
	state = moveDocRequestStateDefaultValue,
	action: MoveDocRequestStateActionTypes
): MoveDocRequestStateType => {
	switch (action.type) {
		case actionTypes.MOVE_DOC_REQUEST_STATE:
			return {
				...state,
				isMovingDocRequestState: true,
				moveRequestId: (action as any).payload.requestId,
			};
		case actionTypes.MOVE_DOC_REQUEST_STATE_SUCCESS:
			return {
				...state,
				isMoveDocRequestStateError: false,
				isMoveDocRequestStateSuccess: true,
				isMovingDocRequestState: false,
			};
		case actionTypes.MOVE_DOC_REQUEST_STATE_FAILURE:
			return {
				...state,
				isMovingDocRequestState: false,
				isMoveDocRequestStateError: true,
				isMoveDocRequestStateSuccess: false,
			};
		case actionTypes.MOVE_DOC_REQUEST_STATE_RESET:
			return {
				...state,
				...moveDocRequestStateDefaultValue,
			};
		default:
			return state;
	}
};

export const removeDocFileReducer = (
	state = removeDocFileDefaultValue,
	action: RemoveDocFileActionTypes
): RemoveDocFileType => {
	switch (action.type) {
		case actionTypes.REMOVE_DOCUMENT_FILE:
			return {
				...state,
				fileKey: (action as any).payload.key,
				isRemovingFile: true,
			};

		case actionTypes.REMOVE_DOCUMENT_FILE_SUCCESS:
			return {
				...state,
				isRemoveFileError: false,
				isRemoveFileSuccess: true,
				isRemovingFile: false,
			};
		case actionTypes.REMOVE_DOCUMENT_FILE_FAILURE:
			return {
				...state,
				isRemovingFile: false,
				isRemoveFileError: true,
				isRemoveFileSuccess: false,
			};

		case actionTypes.REMOVE_DOCUMENT_FILE_RESET:
			return {
				...removeDocFileDefaultValue,
			};
		default:
			return state;
	}
};

export const remindDocRequestReducer = (
	state = remindDocRequestDefaultValue,
	action: RemindDocRequestActionTypes
): RemindDocRequestType => {
	switch (action.type) {
		case actionTypes.REMIND_DOC_REQUEST:
			return {
				...state,
				remindRequestId: (action as any).payload.requestId,
				isRemindingDocRequest: true,
			};
		case actionTypes.REMIND_DOC_REQUEST_SUCCESS:
			return {
				...state,
				isRemindingDocRequest: false,
				isRemindDocRequestSuccess: true,
				isRemindDocRequestError: false,
			};
		case actionTypes.REMIND_DOC_REQUEST_FAILURE:
			return {
				...state,
				isRemindingDocRequest: false,
				isRemindDocRequestSuccess: false,
				isRemindDocRequestError: true,
			};
		case actionTypes.REMIND_DOC_REQUEST_RESET:
			return {
				...state,
				...remindDocRequestDefaultValue,
			};
		default:
			return state;
	}
};

export const removeDocumentActivityReducer = (
	state = removeDocumentActivityDefaultValue,
	action: RemoveDocumentActivityActionTypes
): RemoveDocumentActivityType => {
	switch (action.type) {
		case actionTypes.REMOVE_DOCUMENT_ACTIVITY:
			return {
				...state,
				requestId: (action as any).payload.requestId,
				documentId: (action as any).payload.documentId,
				isRemovingDocumentActivity: true,
			};
		case actionTypes.REMOVE_DOCUMENT_ACTIVITY_SUCCESS:
			return {
				...state,
				isRemovingDocumentActivity: false,
				isRemoveDocumentActivitySuccess: true,
				isRemoveDocumentActivityError: false,
			};
		case actionTypes.REMOVE_DOCUMENT_ACTIVITY_FAILURE:
			return {
				...state,
				isRemovingDocumentActivity: false,
				isRemoveDocumentActivitySuccess: false,
				isRemoveDocumentActivityError: true,
			};
		case actionTypes.REMOVE_DOCUMENT_ACTIVITY_RESET:
			return {
				...state,
				...removeDocumentActivityDefaultValue,
			};
		default:
			return state;
	}
};

export const deleteSignedDocumentReducer = (
	state = deleteSignedDocumentTypeDefaultValue,
	action: DeleteSignedDocumentActionTypes
): DeleteSignedDocumentType => {
	switch (action.type) {
		case actionTypes.DELETE_SIGNED_DOCUMENT:
			return {
				...state,
				isDeletingSignedDocument: true,
			};
		case actionTypes.DELETE_SIGNED_DOCUMENT_SUCCESS:
			return {
				...state,
				isDeletingSignedDocument: false,
				isDeleteSignedDocumentSuccess: true,
				isDeleteSignedDocumentError: false,
			};
		case actionTypes.DELETE_SIGNED_DOCUMENT_FAILURE:
			return {
				...state,
				isDeletingSignedDocument: false,
				isDeleteSignedDocumentSuccess: false,
				isDeleteSignedDocumentError: true,
			};
		case actionTypes.DELETE_SIGNED_DOCUMENT_RESET:
			return {
				...state,
				...removeDocumentActivityDefaultValue,
			};
		default:
			return state;
	}
};

export const docRequestReducer = combineReducers({
	getDocRequests: getDocRequestsReducer,
	getDocRequestDetails: getDocRequestDetailsReducer,
	addDocRequest: addDocRequestReducer,
	editDocRequest: editDocRequestReducer,
	getDocRequestDocument: getDocRequestDocumentReducer,
	completeDocRequestDocument: completeDocRequestDocumentReducer,
	completeDocRequest: completeDocRequestReducer,
	moveDocRequestState: moveDocRequestStateReducer,
	removeDocFile: removeDocFileReducer,
	remindDocRequest: remindDocRequestReducer,
	removeDocumentActivity: removeDocumentActivityReducer,
	getSigningRequestDetails: getSigningRequestDetailsReducer,
	docuSignEnvelopes: docuSignEnvelopesReducer,
	deleteSignedDocument: deleteSignedDocumentReducer,
});
