import * as actionTypes from "./actionTypes";
import { getWorkflowChartDefaultValue, IGetWorkflowChart, IGetWorkflowChartActions } from "./types";

export const getWorkflowChartDataReducer = (
	state = getWorkflowChartDefaultValue,
	action: IGetWorkflowChartActions
): IGetWorkflowChart => {
	switch (action.type) {
		case actionTypes.GET_WORKFLOW_CHART_DATA: {
			return {
				...state,
				gettingWorkflowChartData: true,
			};
		}
		case actionTypes.GET_WORKFLOW_CHART_DATA_SUCCESS: {
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				gettingWorkflowChartData: false,
				getWorkflowChartDataSuccess: true,
				getWorkflowChartDataFailure: false,
			};
		}
		case actionTypes.GET_WORKFLOW_CHART_DATA_FAILURE: {
			return {
				...state,
				gettingWorkflowChartData: false,
				getWorkflowChartDataSuccess: false,
				getWorkflowChartDataFailure: true,
			};
		}
		case actionTypes.GET_WORKFLOW_CHART_DATA_RESET: {
			return {
				...getWorkflowChartDefaultValue,
			};
		}
		default: {
			return state;
		}
	}
};
