import fundDetailsRoutes from "__pages/FundDetails/routes";
import { RoutesType } from "__utils/types";
import React from "react";

const Administration = React.lazy(() => import("__pages/Administration"));
const Funds = React.lazy(() => import("__pages/Funds"));
const FundDetails = React.lazy(() => import("__pages/FundDetails"));
const RequestTemplates = React.lazy(() => import("__pages/RequestTemplates"));
const RequestDocuments = React.lazy(() => import("__pages/RequestDocuments"));
const CreateDocument = React.lazy(() => import("__pages/RequestDocuments/__pages/CreateDocument"));
const EditDocument = React.lazy(() => import("__pages/RequestDocuments/__pages/EditDocument"));
const CreateTemplate = React.lazy(() => import("__pages/RequestTemplates/__pages/CreateTemplate"));
const EditTemplate = React.lazy(() => import("__pages/RequestTemplates/__pages/EditTemplate"));
const WorkflowDashboard = React.lazy(() => import("__pages/WorkflowDashboard"));
const ManageReminders = React.lazy(() => import("__pages/ManageReminders"));
const WorkflowChart = React.lazy(() => import("__pages/WorkflowChart"));

export const routes: RoutesType = [
	{
		key: "0",
		path: ["/", "/funds"],
		component: Funds,
		accessLevels: [1, 2, 3, 4, 5, 6],
		isPrivate: true,
		exact: true,
		shouldDisplay: false,
	},
	{
		key: "1",
		path: "/administration",
		component: Administration,
		accessLevels: [1, 3, 5],
		isPrivate: true,
		shouldDisplay: true,
	},
	{
		key: "2",
		path: "/fund-details",
		component: FundDetails,
		isPrivate: true,
		shouldDisplay: false,
		routes: fundDetailsRoutes,
	},
	{
		key: "3",
		path: "/request-templates",
		exact: true,
		component: RequestTemplates,
		isPrivate: true,
		shouldDisplay: false,
	},
	{
		key: "4",
		path: "/request-documents",
		exact: true,
		component: RequestDocuments,
		isPrivate: true,
		shouldDisplay: false,
	},
	{
		key: "5",
		path: "/request-documents/create",
		component: CreateDocument,
		accessLevels: [1],
		isPrivate: true,
		shouldDisplay: false,
	},
	{
		key: "6",
		path: "/request-templates/create",
		component: CreateTemplate,
		accessLevels: [1],
		isPrivate: true,
		shouldDisplay: false,
	},
	{
		key: "6",
		path: "/request-documents/edit/:id",
		component: EditDocument,
		accessLevels: [1],
		isPrivate: true,
		shouldDisplay: false,
	},
	{
		key: "7",
		path: "/request-templates/edit/:id",
		component: EditTemplate,
		accessLevels: [1],
		isPrivate: true,
		shouldDisplay: false,
	},
	{
		key: "8",
		path: "/workflow-dashboard",
		component: WorkflowDashboard,
		accessLevels: [1],
		isPrivate: true,
		shouldDisplay: false,
	},
	{
		key: "9",
		path: "/manage-reminders/",
		component: ManageReminders,
		accessLevels: [1],
		isPrivate: true,
		shouldDisplay: false,
	},
	{
		key: "9",
		path: "/workflow-chart/",
		component: WorkflowChart,
		accessLevels: [1],
		isPrivate: true,
		shouldDisplay: false,
	},
];

export default routes;
