import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { AddRecurringPaymentPayloadType, IUpdateRecurringPaymentPayload } from "../__redux/types";
import { ADD_PAYMENT_SCHEDULE, UPDATE_PAYMENT_SCHEDULE } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const addRecurringPaymentApi = async ({
	id,
	...rest
}: AddRecurringPaymentPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = ADD_PAYMENT_SCHEDULE.replace(":id", `${id}`);

	return axios.post(url, rest).catch(errorHandler);
};

export const updateRecurringPaymentApi = async ({
	memberId,
	paymentId,
	...rest
}: IUpdateRecurringPaymentPayload): Promise<AxiosResponse<any>> => {
	const url: string = UPDATE_PAYMENT_SCHEDULE.replace(":id", `${memberId}`).replace(":paymentId", `${paymentId}`);

	return axios.patch(url, rest).catch(errorHandler);
};
