import * as actionTypes from "./actionTypes";

import {
	IGetReminderCountActions,
	IGetReminderActions,
	IGetReminderDetailsActions,
	IGetReminderDetailsPayload,
	IReleaseReminderActions,
	ICancelReminderActions,
	ICancelReminderPayload,
	IReleaseReminderPayload,
} from "./types";

// Get Reminder Count
export const getReminderCountCtr = (): IGetReminderCountActions => ({
	type: actionTypes.GET_REMINDERS_COUNT,
});

// Get Reminder Count Reset
export const getReminderCountResetCtr = (): IGetReminderCountActions => ({
	type: actionTypes.GET_REMINDERS_COUNT_RESET,
});

// Get Reminder
export const getReminderCtr = (): IGetReminderActions => ({
	type: actionTypes.GET_REMINDERS,
});

// Get Reminder Reset
export const getReminderResetCtr = (): IGetReminderActions => ({
	type: actionTypes.GET_REMINDERS_RESET,
});

// Get Reminder Details
export const getReminderDetailsCtr = (payload: IGetReminderDetailsPayload): IGetReminderDetailsActions => ({
	type: actionTypes.GET_REMINDERS_DETAILS,
	payload,
});

// Get Reminder Details Reset
export const getReminderDetailsResetCtr = (): IGetReminderDetailsActions => ({
	type: actionTypes.GET_REMINDERS_DETAILS_RESET,
});

// Release  Reminders
export const releaseReminderCtr = (payload: Array<IReleaseReminderPayload>): IReleaseReminderActions => ({
	type: actionTypes.RELEASE_REMINDER,
	payload,
});

//  Release  Reminders Reset
export const releaseReminderResetCtr = (): IReleaseReminderActions => ({
	type: actionTypes.RELEASE_REMINDER_RESET,
});

// Cancel  Reminders
export const cancelReminderCtr = (payload: Array<ICancelReminderPayload>): ICancelReminderActions => ({
	type: actionTypes.CANCEL_REMINDER,
	payload,
});

//  Cancel  Reminders Reset
export const cancelReminderResetCtr = (): ICancelReminderActions => ({
	type: actionTypes.CANCEL_REMINDER_RESET,
});
