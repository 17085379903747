import { call, put, takeLatest } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const addPayment = function* (action: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.addPaymentApi, action.payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.ADD_PAYMENT_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.ADD_PAYMENT_FAILURE,
				addPaymentError: data,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.ADD_PAYMENT_FAILURE,
		});
	}
};

export const addPaymentSaga = function* (): any {
	yield takeLatest(actionTypes.ADD_PAYMENT, addPayment);
};

const updatePayment = function* (action: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.updatePaymentApi, action.payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.UPDATE_PAYMENT_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({
				type: actionTypes.UPDATE_PAYMENT_FAILURE,
				addPaymentError: data,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.ADD_PAYMENT_FAILURE,
		});
	}
};

export const updatePaymentSaga = function* (): any {
	yield takeLatest(actionTypes.UPDATE_PAYMENT, updatePayment);
};
