export const GET_FUNDS = "GET_FUNDS";
export const GET_FUNDS_SUCCESS = "GET_FUNDS_SUCCESS";
export const GET_FUNDS_FAILURE = "GET_FUNDS_FAILURE";
export const GET_FUNDS_RESET = "GET_FUNDS_RESET";
export const GET_FUNDS_CANCEL = "GET_FUNDS_CANCEL";
export const GET_FUNDS_NOOP = "GET_FUNDS_NOOP";

export const GET_FUNDS_ON_SCROLL = "GET_FUNDS_ON_SCROLL";
export const GET_FUNDS_ON_SCROLL_SUCCESS = "GET_FUNDS_ON_SCROLL_SUCCESS";

export const DELETE_FUND = "DELETE_FUND";
export const DELETE_FUND_SUCCESS = "DELETE_FUND_SUCCESS";
export const DELETE_FUND_FAILURE = "DELETE_FUND_FAILURE";
export const DELETE_FUND_RESET = "DELETE_FUND_RESET";

export const ARCHIVE_FUND = "ARCHIVE_FUND";
export const ARCHIVE_FUND_SUCCESS = "ARCHIVE_FUND_SUCCESS";
export const ARCHIVE_FUND_FAILURE = "ARCHIVE_FUND_FAILURE";
export const ARCHIVE_FUND_RESET = "ARCHIVE_FUND_RESET";

export const RESTORE_FUND = "RESTORE_FUND";
export const RESTORE_FUND_SUCCESS = "RESTORE_FUND_SUCCESS";
export const RESTORE_FUND_FAILURE = "RESTORE_FUND_FAILURE";
export const RESTORE_FUND_RESET = "RESTORE_FUND_RESET";

export const DEACTIVATE_FUND = "DEACTIVATE_FUND";
export const DEACTIVATE_FUND_SUCCESS = "DEACTIVATE_FUND_SUCCESS";
export const DEACTIVATE_FUND_FAILURE = "DEACTIVATE_FUND_FAILURE";
export const DEACTIVATE_FUND_RESET = "DEACTIVATE_FUND_RESET";

export const FAVORITE_FUND = "FAVORITE_FUND";
export const FAVORITE_FUND_SUCCESS = "FAVORITE_FUND_SUCCESS";
export const FAVORITE_FUND_FAILURE = "FAVORITE_FUND_FAILURE";

export const SET_SELECTED_ROWS = "SET_SELECTED_ROWS";
export const SET_SELECTED_ROWS_RESET = "SET_SELECTED_ROWS_RESET";

export const GET_REQUEST_SUMMARY = "GET_REQUEST_SUMMARY";
export const GET_REQUEST_SUMMARY_SUCCESS = "GET_REQUEST_SUMMARY_SUCCESS";
export const GET_REQUEST_SUMMARY_FAILURE = "GET_REQUEST_SUMMARY_FAILURE";
export const GET_REQUEST_SUMMARY_RESET = "GET_REQUEST_SUMMARY_RESET";
export const GET_REQUEST_SUMMARY_CANCEL = "GET_REQUEST_SUMMARY_CANCEL";

export const GET_SMSF_COMPLYING_FUNDS_COUNT = "GET_SMSF_COMPLYING_FUNDS_COUNT";
export const GET_SMSF_COMPLYING_FUNDS_COUNT_SUCCESS = "GET_SMSF_COMPLYING_FUNDS_COUNT_SUCCESS";
export const GET_SMSF_COMPLYING_FUNDS_COUNT_FAILURE = "GET_SMSF_COMPLYING_FUNDS_COUNT";

export const GET_FUND_USER_META = "GET_FUND_USER_META";
export const GET_FUND_USER_META_SUCCESS = "GET_FUND_USER_META_SUCCESS";
export const GET_FUND_USER_META_FAILURE = "GET_FUND_USER_META_FAILURE";

export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_FAILURE = "GET_TAGS_FAILURE";
export const GET_TAGS_RESET = "GET_TAGS_RESET";

export const DOWNLOAD_CLIENT_LIST = "DOWNLOAD_CLIENT_LIST";
export const DOWNLOAD_CLIENT_LIST_SUCCESS = "DOWNLOAD_CLIENT_LIST_SUCCESS";
export const DOWNLOAD_CLIENT_LIST_FAILURE = "DOWNLOAD_CLIENT_LIST_FAILURE";
export const DOWNLOAD_CLIENT_LIST_RESET = "DOWNLOAD_CLIENT_LIST_RESET";
