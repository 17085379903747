import { CaretDownFilled, UserOutlined } from "@ant-design/icons";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Space from "antd/es/space";
import cx from "classnames";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import IconWrapper from "__common/IconWrapper";
import { UserProfileType } from "__pages/User/__redux/types";
import { BREAKPOINTS } from "__utils/constants";
import { isUserAdmin } from "__utils/helpers";
import Badge from "antd/es/badge";
import EstablishFundModal from "../../EstablishFundModal";
import { SetHeaderPreviewType } from "../../__redux/types";
import { DEFAULT_LOGO_HEIGHT } from "../../__utils/constants";
import { DesktopHeaderContainer, Left, LogoWrapper, Right, SessionWidget } from "./style";

type Props = {
	userMenu: React.ReactElement;
	workflowDropMenu: React.ReactElement;
	requestDropMenu: React.ReactElement;
	headerPreview: SetHeaderPreviewType;
	userProfile: UserProfileType;
	reminderCount: number;
};

const DesktopHeader: React.FC<Props> = ({
	userMenu,
	workflowDropMenu,
	requestDropMenu,
	headerPreview,
	userProfile,
	reminderCount,
}: Props) => {
	const history = useHistory();

	const { fullName, brandName, level, logo, logoHeight, darkMode } = userProfile;
	const { previewOn, darkMode: previewDarkMode, logoHeight: previewLogoHeight, logo: previewLogo } = headerPreview;

	const isSM: boolean = useMediaQuery({ query: BREAKPOINTS.SM });

	const getLogoHeight = (): number => {
		if (previewOn) {
			return previewLogoHeight;
		}
		if (logoHeight) {
			return logoHeight;
		}
		return DEFAULT_LOGO_HEIGHT;
	};

	return (
		<DesktopHeaderContainer>
			<Left>
				<LogoWrapper>
					<Link to="/">
						<img src={previewLogo || logo} alt="logo" style={{ height: `${getLogoHeight()}px` }} />
					</Link>
				</LogoWrapper>
			</Left>
			<Right className={cx({ "dark-mode": previewDarkMode || darkMode })}>
				<Space size="small">
					<EstablishFundModal />

					{isUserAdmin(level) && (
						<>
							<Dropdown
								overlay={requestDropMenu}
								className="header-btn"
								placement="bottomCenter"
								trigger={["click"]}
								overlayClassName="dropdown"
								arrow
							>
								<Badge count={reminderCount} size="small" showZero={false}>
									<Button className="header-btn">Requests</Button>
								</Badge>
							</Dropdown>
							<Dropdown
								overlay={workflowDropMenu}
								className="header-btn"
								placement="bottomCenter"
								trigger={["click"]}
								overlayClassName="dropdown"
								arrow
							>
								<Button className="header-btn">Workflow</Button>
							</Dropdown>
						</>
					)}
					{[1, 3, 5].includes(level) && (
						<Button className="header-btn" onClick={() => history.push("/administration")}>
							Administration
						</Button>
					)}
				</Space>
				<SessionWidget className={cx({ "dark-mode": previewDarkMode || darkMode })}>
					<Dropdown
						overlay={userMenu}
						trigger={["click"]}
						overlayClassName="session-dropdown"
						placement="bottomCenter"
						arrow
					>
						<div>
							<div className="icon-wrapper">
								<IconWrapper background="#979797">
									<UserOutlined />
								</IconWrapper>
							</div>
							{!isSM && (
								<div className="name-brand">
									<div className="name">{fullName}</div>
									<div className="brand">{brandName}</div>
								</div>
							)}

							<div className="userSetting">
								<p className="ant-dropdown-link">
									<CaretDownFilled />
								</p>
							</div>
						</div>
					</Dropdown>
				</SessionWidget>
			</Right>
		</DesktopHeaderContainer>
	);
};

export default DesktopHeader;
