import { errorHandler, getAxiosInstance } from "__utils/helpers";

import { API_ROOT } from "__utils/constants";
import { AxiosInstance, AxiosResponse } from "axios";
import {
	COMPETE_DOC_REQUEST,
	COMPETE_DOC_REQUEST_DOCUMENT,
	GET_DOC_REQUESTS,
	GET_DOC_REQUEST_DETAILS,
	GET_DOC_REQUEST_DOCUMENT,
	GET_SIGNING_REQUEST_DETAILS,
	MOVE_DOC_REQUEST_STATE,
	REMIND_DOC_REQUEST,
	REMOVE_DOC_ACTIVITY,
	REMOVE_DOC_FILE,
	DELETE_DOC_REQUEST,
} from "./constants";
import {
	AddDocRequestPayloadType,
	CompleteDocRequestDocumentPayloadType,
	CompleteDocRequestPayloadType,
	EditDocRequestPayloadType,
	GetDocRequestDetailsPayloadType,
	GetDocRequestDocumentPayloadType,
	GetDocRequestsPayloadType,
	GetSigningRequestDetailsPayloadType,
	MoveDocRequestStatePayloadType,
	RemindDocRequestPayloadType,
	RemoveDocFilePayloadType,
	RemoveDocumentActivityPayloadType,
	DeleteSignedDocumentPayloadType,
} from "../__redux/types";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getDocRequestsApi = async ({ memberId }: GetDocRequestsPayloadType): Promise<AxiosResponse<any>> =>
	axios.get(`${GET_DOC_REQUESTS}/${memberId}/`).catch(errorHandler);

export const getDocRequestDetailsApi = async ({
	memberId,
	id,
}: GetDocRequestDetailsPayloadType): Promise<AxiosResponse<any>> =>
	axios.get(`${GET_DOC_REQUEST_DETAILS}/${memberId}/${id}/`).catch(errorHandler);

export const getSigningRequestDetailsApi = async ({
	memberId,
	envelopeId,
}: GetSigningRequestDetailsPayloadType): Promise<AxiosResponse<any>> => {
	const url = GET_SIGNING_REQUEST_DETAILS.replace(":memberId", `${memberId}`).replace(":envelopeId", envelopeId);
	return axios.get(url).catch(errorHandler);
};

export const addDocRequestApi = async ({ memberId, ...rest }: AddDocRequestPayloadType): Promise<AxiosResponse<any>> =>
	axios.post(`${GET_DOC_REQUESTS}/${memberId}/`, rest).catch(errorHandler);

export const editDocRequestApi = async ({
	memberId,
	...rest
}: EditDocRequestPayloadType): Promise<AxiosResponse<any>> => {
	const { id } = rest;
	return axios.put(`${GET_DOC_REQUESTS}/${memberId}/${id}/`, rest).catch(errorHandler);
};

export const getDocRequestDocumentApi = async ({
	requestId,
	id,
}: GetDocRequestDocumentPayloadType): Promise<AxiosResponse<any>> =>
	axios.get(`${GET_DOC_REQUEST_DOCUMENT}/${requestId}/document/${id}/`).catch(errorHandler);

export const completeDocRequestDocumentApi = async ({
	memberId,
	requestId,
	documentId,
}: CompleteDocRequestDocumentPayloadType): Promise<AxiosResponse<any>> =>
	axios
		.put(`${COMPETE_DOC_REQUEST_DOCUMENT}/${memberId}/complete/${requestId}/document/${documentId}/`)
		.catch(errorHandler);

// DEPRECATED
export const completeDocRequestApi = async ({
	memberId,
	requestId,
}: CompleteDocRequestPayloadType): Promise<AxiosResponse<any>> =>
	axios.put(`${COMPETE_DOC_REQUEST}/${memberId}/complete/${requestId}/`).catch(errorHandler);

export const moveDocRequestStateApi = async ({
	memberId,
	requestId,
	state,
}: MoveDocRequestStatePayloadType): Promise<AxiosResponse<any>> => {
	const url: string = MOVE_DOC_REQUEST_STATE.replace(":memberId", `${memberId}`).replace(
		":requestId",
		`${requestId}`
	);
	return axios.put(url, { state }).catch(errorHandler);
};

export const removeDocFileApi = async ({ memberId, key }: RemoveDocFilePayloadType): Promise<AxiosResponse<any>> => {
	const url: string = REMOVE_DOC_FILE.replace(":memberId", `${memberId}`).replace(":key", `${key}`);

	return axios.delete(url).catch(errorHandler);
};

export const remindDocRequestApi = async ({
	memberId,
	requestId,
}: RemindDocRequestPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = REMIND_DOC_REQUEST.replace(":memberId", `${memberId}`).replace(":requestId", `${requestId}`);
	return axios.put(url).catch(errorHandler);
};

export const removeDocActivityApi = async ({
	memberId,
	documentId,
}: RemoveDocumentActivityPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = REMOVE_DOC_ACTIVITY.replace(":memberId", `${memberId}`).replace(":documentId", `${documentId}`);

	return axios.put(url).catch(errorHandler);
};

export const removeDocReqApi = async ({
	memberId,
	envelopeId,
}: DeleteSignedDocumentPayloadType): Promise<AxiosResponse<any>> => {
	const url: string = DELETE_DOC_REQUEST.replace(":memberId", `${memberId}`).replace(":envelopeId", `${envelopeId}`);

	return axios.delete(url).catch(errorHandler);
};
