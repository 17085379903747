export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_FAILURE = "GET_INVOICES_FAILURE";

export const DOWNLOAD_INVOICE = "DOWNLOAD_INVOICE";
export const DOWNLOAD_INVOICE_SUCCESS = "DOWNLOAD_INVOICE_SUCCESS";
export const DOWNLOAD_INVOICE_FAILURE = "DOWNLOAD_INVOICE_FAILURE";

export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_FAILURE = "UPDATE_INVOICE_FAILURE";
export const UPDATE_INVOICE_RESET = "UPDATE_INVOICE_RESET";
