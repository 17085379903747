import { AxiosInstance, AxiosResponse } from "axios";
import { API_ROOT } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
// import { } from "../__redux/types";
import { GET_WORKFLOW_CHART_DATA } from "./constants";

const axios: AxiosInstance = getAxiosInstance(API_ROOT);

export const getWorkflowChartDataApi = async (): Promise<AxiosResponse<any>> =>
	axios.get(GET_WORKFLOW_CHART_DATA).catch(errorHandler);
