import { ApiErrorType } from "__utils/types";
import * as actionTypes from "./actionTypes";
/**
 * Create Recurring Payment
 */

export type AddRecurringPaymentType = {
	readonly isAddingRecurringPayment: boolean;
	readonly isAddRecurringPaymentSuccess: boolean;
	readonly isAddRecurringPaymentError: boolean;
	readonly addRecurringPaymentError: string | ApiErrorType;
};

export const addRecurringPaymentDefaultValue: AddRecurringPaymentType = {
	isAddingRecurringPayment: false,
	isAddRecurringPaymentSuccess: false,
	isAddRecurringPaymentError: false,
	addRecurringPaymentError: {},
};

export type AddRecurringPaymentPayloadType = {
	readonly id: number;
	readonly debitDate: string;
	readonly amount: string;
	readonly reference: string;
	readonly description: string;
};

export type AddRecurringPayment = {
	(payload: AddRecurringPaymentPayloadType): AddRecurringPaymentActionTypes;
};

type AddRecurringPaymentAction = {
	type: typeof actionTypes.ADD_RECURRING_PAYMENT;
	payload: AddRecurringPaymentPayloadType;
};

type AddRecurringPaymentSuccessAction = {
	type: typeof actionTypes.ADD_RECURRING_PAYMENT_SUCCESS;
	payload: AddRecurringPaymentPayloadType;
};

type AddRecurringPaymentFailureAction = {
	type: typeof actionTypes.ADD_RECURRING_PAYMENT_FAILURE;
	payload: AddRecurringPaymentPayloadType;
};

type AddRecurringPaymentResetAction = {
	type: typeof actionTypes.ADD_RECURRING_PAYMENT_RESET;
};

export type AddRecurringPaymentActionTypes =
	| AddRecurringPaymentAction
	| AddRecurringPaymentSuccessAction
	| AddRecurringPaymentFailureAction
	| AddRecurringPaymentResetAction;

// Update

export type IUpdateRecurringPayment = {
	readonly isUpdatingRecurringPayment: boolean;
	readonly isUpdateRecurringPaymentSuccess: boolean;
	readonly isUpdateRecurringPaymentError: boolean;
	readonly updateRecurringPaymentError: string | ApiErrorType;
};

export const updateRecurringPaymentDefaultValue: IUpdateRecurringPayment = {
	isUpdatingRecurringPayment: false,
	isUpdateRecurringPaymentSuccess: false,
	isUpdateRecurringPaymentError: false,
	updateRecurringPaymentError: {},
};

export type IUpdateRecurringPaymentPayload = {
	readonly memberId: number;
	readonly paymentId: number;
	readonly debitDate: string;
	readonly amount: string;
	readonly reference: string;
	readonly description: string;
};

type IUpdateRecurringPaymentAction = {
	type: typeof actionTypes.UPDATE_RECURRING_PAYMENT;
	payload: IUpdateRecurringPaymentPayload;
};

type IUpdateRecurringPaymentSuccessAction = {
	type: typeof actionTypes.UPDATE_RECURRING_PAYMENT_SUCCESS;
	payload: IUpdateRecurringPaymentPayload;
};

type IUpdateRecurringPaymentFailureAction = {
	type: typeof actionTypes.UPDATE_RECURRING_PAYMENT_FAILURE;
	payload: IUpdateRecurringPaymentPayload;
};

type IUpdateRecurringPaymentResetAction = {
	type: typeof actionTypes.UPDATE_RECURRING_PAYMENT_RESET;
};

export type IUpdateRecurringPaymentActions =
	| IUpdateRecurringPaymentAction
	| IUpdateRecurringPaymentSuccessAction
	| IUpdateRecurringPaymentFailureAction
	| IUpdateRecurringPaymentResetAction;
