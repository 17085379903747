import fileDownload from "js-file-download";
import { call, put, takeLatest } from "redux-saga/effects";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";
import { InvoicesType, InvoiceType } from "./types";

const getInvoices = function* (action: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getInvoicesApi, action.payload);
		const { status, data } = response;

		if (status === 200) {
			let invoices: InvoicesType = [];
			if (data) {
				invoices = data.data.payment.map((invoice: InvoiceType) => ({
					...invoice,
				}));
			}

			yield put({
				type: actionTypes.GET_INVOICES_SUCCESS,
				invoices,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_INVOICES_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_INVOICES_FAILURE });
	}
};

export const getInvoicesSaga = function* (): any {
	yield takeLatest(actionTypes.GET_INVOICES, getInvoices);
};

const extractFilename = (raw: string): string => raw.split(" ")[1].split("=")[1];

const downloadInvoice = function* (action: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.downloadInvoiceApi, action.payload);
		const { status, data } = response;
		yield call(fileDownload, data, extractFilename(response.headers["content-disposition"]));

		if (status === 200) {
			yield put({
				type: actionTypes.DOWNLOAD_INVOICE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.DOWNLOAD_INVOICE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.DOWNLOAD_INVOICE_FAILURE });
	}
};

export const downloadInvoiceSaga = function* (): any {
	yield takeLatest(actionTypes.DOWNLOAD_INVOICE, downloadInvoice);
};

const updateInvoice = function* (action: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.updateInvoiceApi, action.payload);
		const { status } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.UPDATE_INVOICE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.UPDATE_INVOICE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.UPDATE_INVOICE_FAILURE });
	}
};

export const updateInvoiceSaga = function* (): any {
	yield takeLatest(actionTypes.UPDATE_INVOICE, updateInvoice);
};
