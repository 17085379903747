import { ResponseGeneratorType } from "__utils/types";
import { call, put, takeLatest, select } from "redux-saga/effects";

import * as actionTypes from "./actionTypes";
import * as api from "../__api";
import { getReminderDetailsSlr, getReminderSlr } from "./selectors";
import { IGetReminderDetails, IReminder, IReleaseReminderPayload, ICancelReminderPayload } from "./types";

const getReminderCount = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getRemindersCountAPI);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_REMINDERS_COUNT_SUCCESS,
				reminderCount: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_REMINDERS_COUNT_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_REMINDERS_COUNT_FAILURE });
	}
};

export const getReminderCountSaga = function* (): any {
	yield takeLatest(actionTypes.GET_REMINDERS_COUNT, getReminderCount);
};

const getReminder = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getRemindersAPI);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_REMINDERS_SUCCESS,
				reminders: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_REMINDERS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_REMINDERS_FAILURE });
	}
};

export const getReminderSaga = function* (): any {
	yield takeLatest(actionTypes.GET_REMINDERS, getReminder);
};

const getReminderDetails = function* ({ payload }: any): any {
	const getReminderDetails: IGetReminderDetails = yield select(getReminderDetailsSlr);
	const { reminderDetails: currReminderDetail } = getReminderDetails;
	try {
		const response: ResponseGeneratorType = yield call(api.getReminderDetailsAPI, payload);
		const { status, data } = response;

		if (status === 200) {
			const _data = [...currReminderDetail, data];
			yield put({
				type: actionTypes.GET_REMINDERS_DETAILS_SUCCESS,
				reminderDetails: _data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_REMINDERS_DETAILS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_REMINDERS_DETAILS_FAILURE });
	}
};

export const getReminderDetailsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_REMINDERS_DETAILS, getReminderDetails);
};

const releaseReminder = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.releaseReminderAPI, payload);

		const { reminders } = yield select(getReminderSlr);
		const { status } = response;
		if (status === 200) {
			const filteredReminders = reminders.filter(
				(reminder: IReminder) =>
					!payload.some((item: IReleaseReminderPayload) => item.requestId === reminder.requestId)
			);
			yield put({
				type: actionTypes.RELEASE_REMINDER_SUCCESS,
			});
			yield put({
				type: actionTypes.GET_REMINDERS_SUCCESS,
				reminders: filteredReminders,
			});

			yield put({
				type: actionTypes.GET_REMINDERS_COUNT_SUCCESS,
				reminderCount: filteredReminders.length,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.RELEASE_REMINDER_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.RELEASE_REMINDER_FAILURE });
	}
};

export const releaseReminderSaga = function* (): any {
	yield takeLatest(actionTypes.RELEASE_REMINDER, releaseReminder);
};

const cancelReminder = function* ({ payload }: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.cancelReminderAPI, payload);

		const { reminders } = yield select(getReminderSlr);
		const { status } = response;

		if (status === 204) {
			const filteredReminders = reminders.filter(
				(reminder: IReminder) =>
					!payload.some((item: ICancelReminderPayload) => item.requestId === reminder.requestId)
			);

			yield put({
				type: actionTypes.CANCEL_REMINDER_SUCCESS,
			});

			yield put({
				type: actionTypes.GET_REMINDERS_SUCCESS,
				reminders: filteredReminders,
			});

			yield put({
				type: actionTypes.GET_REMINDERS_COUNT_SUCCESS,
				reminderCount: filteredReminders.length,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.CANCEL_REMINDER_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.CANCEL_REMINDER_FAILURE });
	}
};

export const cancelReminderSaga = function* (): any {
	yield takeLatest(actionTypes.CANCEL_REMINDER, cancelReminder);
};
