import { ResponseGeneratorType } from "__utils/types";
import { call, put, takeLatest } from "redux-saga/effects";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const getWorkflowChartData = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getWorkflowChartDataApi);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_WORKFLOW_CHART_DATA_SUCCESS,
				workflowChartData: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_WORKFLOW_CHART_DATA_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_WORKFLOW_CHART_DATA_FAILURE });
	}
};

export const getWorkflowChartDataSaga = function* (): any {
	yield takeLatest(actionTypes.GET_WORKFLOW_CHART_DATA, getWorkflowChartData);
};
