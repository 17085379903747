import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Row from "antd/es/row";
import Select from "antd/es/select";
import { FormInstance } from "antd/lib/form";
import React from "react";
import DropdownRender from "__common/DropdownRender";
import { FloatLabel } from "__common/FloatLabel";
import * as lang from "__utils/lang";
import { ObjectType } from "__utils/types";
import { statesInitialName, identificationTypeList } from "../../../__utils/constants";
import * as createLang from "../../../__utils/lang";

type Props = {
	form: FormInstance;
	isDisabled: boolean;
};

const IdentificationFields: React.FC<Props> = ({ form, isDisabled }: Props) => {
	const { getFieldValue, setFieldsValue } = form;
	const [identification, setIdentification] = React.useState<string>("");

	const onSetIdentification = (type: string): void => {
		console.log(type);
		setIdentification(type);
		setFieldsValue({
			identificationNumber: "",
			identificationStateIssued: "",
		});
	};

	React.useEffect(() => {
		if (getFieldValue(createLang.IDENTIFICATION_TYPE) !== "DLPPnotAvailable") {
			setIdentification(getFieldValue(createLang.IDENTIFICATION_TYPE));
		}
	}, [getFieldValue]);

	React.useEffect(() => {
		document.querySelectorAll(".ant-select-selection-search-input").forEach((e) => {
			e.setAttribute("autocomplete", "chrome-off");
			// Update value from time to time as Google like fuck web developers :-D
		});
	}, []);

	return (
		<>
			<Row gutter={[16, 8]}>
				<Col xs={24} xl={8}>
					<FloatLabel
						label={createLang.IDENTIFICATION_TYPE_LABEL}
						getValue={() => getFieldValue(createLang.IDENTIFICATION_TYPE)}
					>
						<Form.Item
							name={createLang.IDENTIFICATION_TYPE}
							rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
						>
							<Select
								getPopupContainer={(trigger: any) => trigger.parentElement}
								dropdownRender={(menu: any) => <DropdownRender>{menu}</DropdownRender>}
								disabled={isDisabled}
								showAction={["click", "focus"]}
								showSearch
								onChange={onSetIdentification}
							>
								{identificationTypeList.map((identificationType: ObjectType, idx) => {
									const { name, value } = identificationType;
									return (
										<Select.Option key={idx} value={value}>
											{name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					</FloatLabel>
				</Col>
				{identification && identification !== "DLPPnotAvailable" && (
					<Col xs={24} xl={8}>
						<FloatLabel
							label={createLang.IDENTIFICATION_NUMBER_LABEL}
							getValue={() => getFieldValue(createLang.IDENTIFICATION_NUMBER)}
						>
							<Form.Item
								name={createLang.IDENTIFICATION_NUMBER}
								rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
							>
								<Input disabled={isDisabled} autoComplete="chrome-off" />
							</Form.Item>
						</FloatLabel>
					</Col>
				)}
				{identification === "DriversLicence" && (
					<Col xs={24} xl={8}>
						<FloatLabel
							label={createLang.STATE_ISSUED_LABEL}
							getValue={() => getFieldValue(createLang.STATE_ISSUED)}
						>
							<Form.Item
								name={createLang.STATE_ISSUED}
								rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
							>
								<Select
									getPopupContainer={(trigger: any) => trigger.parentElement}
									dropdownRender={(menu: any) => <DropdownRender>{menu}</DropdownRender>}
									disabled={isDisabled}
									showAction={["click", "focus"]}
								>
									{statesInitialName.map((type, idx) => (
										<Select.Option key={idx} value={type}>
											{type}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</FloatLabel>
					</Col>
				)}
			</Row>
			<Row gutter={[16, 8]}>
				{identification === "DriversLicence" && (
					<Col xs={24} xl={8}>
						<FloatLabel
							label={createLang.CARD_NUMBER_LABEL}
							getValue={() => getFieldValue(createLang.CARD_NUMBER)}
						>
							<Form.Item name={createLang.CARD_NUMBER}>
								<Input disabled={isDisabled} autoComplete="chrome-off" />
							</Form.Item>
						</FloatLabel>
					</Col>
				)}
			</Row>
		</>
	);
};

export default IdentificationFields;
