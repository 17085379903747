import * as actionTypes from "./actionTypes";

/**
 * Get ReminderCount
 */

export type IGetReminderCount = {
	readonly reminderCount: number;
	readonly isGettingReminderCount: boolean;
	readonly isGetReminderCountSuccess: boolean;
	readonly isGetReminderCountError: boolean;
};

export const getReminderCountDefaultValue: IGetReminderCount = {
	reminderCount: 0,
	isGettingReminderCount: false,
	isGetReminderCountSuccess: false,
	isGetReminderCountError: false,
};

type IGetReminderCountAction = {
	type: typeof actionTypes.GET_REMINDERS_COUNT;
};

type IGetReminderCountSuccessAction = {
	type: typeof actionTypes.GET_REMINDERS_COUNT_SUCCESS;
};

type IGetReminderCountFailureAction = {
	type: typeof actionTypes.GET_REMINDERS_COUNT_FAILURE;
};

type IGetReminderCountResetAction = {
	type: typeof actionTypes.GET_REMINDERS_COUNT_RESET;
};

export type IGetReminderCountActions =
	| IGetReminderCountAction
	| IGetReminderCountSuccessAction
	| IGetReminderCountFailureAction
	| IGetReminderCountResetAction;
/**
 * Get Reminder
 */
export type IReminder = {
	readonly memberId: number;
	readonly fundName: string;
	readonly requestId: number;
	readonly requestTitle: string;
	readonly type: string;
};

export type IGetReminder = {
	readonly reminders: IReminder[];
	readonly isGettingReminder: boolean;
	readonly isGetReminderSuccess: boolean;
	readonly isGetReminderError: boolean;
};

export const getReminderDefaultValue: IGetReminder = {
	reminders: [],
	isGettingReminder: false,
	isGetReminderSuccess: false,
	isGetReminderError: false,
};

type IGetReminderAction = {
	type: typeof actionTypes.GET_REMINDERS;
};

type IGetReminderSuccessAction = {
	type: typeof actionTypes.GET_REMINDERS_SUCCESS;
};

type IGetReminderFailureAction = {
	type: typeof actionTypes.GET_REMINDERS_FAILURE;
};

type IGetReminderResetAction = {
	type: typeof actionTypes.GET_REMINDERS_RESET;
};

export type IGetReminderActions =
	| IGetReminderAction
	| IGetReminderSuccessAction
	| IGetReminderFailureAction
	| IGetReminderResetAction;

/**
 * Get ReminderDetails Details
 */

export type IReminderFile = {
	readonly fileName: string;
	readonly originalFileName: string;
	readonly key: string;
	readonly uploadDate: string;
};

export type IReminderDocument = {
	readonly id: number;
	readonly documentId: number;
	readonly title: string;
	readonly category: string;
	readonly information: string;
	readonly description: string;
	readonly activity: boolean;
	readonly isComplete: boolean;
	readonly availabilityDate: string;
	readonly difficulty: number;
	readonly files: IReminderFile[];
};

export type IReminderDetail = {
	readonly id: number;
	readonly title: string;
	readonly state: string;
	readonly movements: Array<string>;
	readonly description: string;
	readonly dueDate: string;
	readonly updatedDate: string;
	readonly documents: IReminderDocument[];
};
export type ICurrentReminder = {
	readonly memberId: number;
	readonly requestId: number;
};

export type IGetReminderDetails = {
	readonly reminderDetails: IReminderDetail[];
	readonly currReminder: ICurrentReminder;
	readonly isGettingReminderDetails: boolean;
	readonly isGetReminderDetailsSuccess: boolean;
	readonly isGetReminderDetailsError: boolean;
};

export const getReminderDetailsDefaultValue: IGetReminderDetails = {
	reminderDetails: [],
	currReminder: {
		memberId: 0,
		requestId: 0,
	},
	isGettingReminderDetails: false,
	isGetReminderDetailsSuccess: false,
	isGetReminderDetailsError: false,
};

export type IGetReminderDetailsPayload = {
	readonly memberId: number;
	readonly requestId: number;
};

type IGetReminderDetailsAction = {
	type: typeof actionTypes.GET_REMINDERS_DETAILS;
	payload: IGetReminderDetailsPayload;
};

type IGetReminderDetailsSuccessAction = {
	type: typeof actionTypes.GET_REMINDERS_DETAILS_SUCCESS;
};

type IGetReminderDetailsFailureAction = {
	type: typeof actionTypes.GET_REMINDERS_DETAILS_FAILURE;
};

type IGetReminderDetailsResetAction = {
	type: typeof actionTypes.GET_REMINDERS_DETAILS_RESET;
};

export type IGetReminderDetailsActions =
	| IGetReminderDetailsAction
	| IGetReminderDetailsSuccessAction
	| IGetReminderDetailsFailureAction
	| IGetReminderDetailsResetAction;

/**
 * RELEASE ALL REMINDERS
 */

export type IReleaseReminder = {
	readonly isReleasingReminder: boolean;
	readonly isReleaseReminderSuccess: boolean;
	readonly isReleaseReminderError: boolean;
};

export const releaseReminderDefaultValue: IReleaseReminder = {
	isReleasingReminder: false,
	isReleaseReminderSuccess: false,
	isReleaseReminderError: false,
};

export type IReleaseReminderPayload = {
	readonly memberId: number;
	readonly requestId: number;
	readonly type: string;
};

type IReleaseReminderAction = {
	type: typeof actionTypes.RELEASE_REMINDER;
	payload: Array<IReleaseReminderPayload>;
};

type IReleaseReminderSuccessAction = {
	type: typeof actionTypes.RELEASE_REMINDER_SUCCESS;
};

type IReleaseReminderFailureAction = {
	type: typeof actionTypes.RELEASE_REMINDER_FAILURE;
};

type IReleaseReminderResetAction = {
	type: typeof actionTypes.RELEASE_REMINDER_RESET;
};

export type IReleaseReminderActions =
	| IReleaseReminderAction
	| IReleaseReminderSuccessAction
	| IReleaseReminderFailureAction
	| IReleaseReminderResetAction;

/**
 * CANCEL ALL REMINDERS
 */

export type ICancelReminder = {
	readonly isCancelingReminder: boolean;
	readonly isCancelReminderSuccess: boolean;
	readonly isCancelReminderError: boolean;
};

export const cancelReminderDefaultValue: ICancelReminder = {
	isCancelingReminder: false,
	isCancelReminderSuccess: false,
	isCancelReminderError: false,
};

export type ICancelReminderPayload = {
	readonly memberId: number;
	readonly requestId: number;
};

type ICancelReminderAction = {
	type: typeof actionTypes.CANCEL_REMINDER;
	payload: Array<ICancelReminderPayload>;
};

type ICancelReminderSuccessAction = {
	type: typeof actionTypes.CANCEL_REMINDER_SUCCESS;
};

type ICancelReminderFailureAction = {
	type: typeof actionTypes.CANCEL_REMINDER_FAILURE;
};

type ICancelReminderResetAction = {
	type: typeof actionTypes.CANCEL_REMINDER_RESET;
};

export type ICancelReminderActions =
	| ICancelReminderAction
	| ICancelReminderSuccessAction
	| ICancelReminderFailureAction
	| ICancelReminderResetAction;
