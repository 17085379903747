import * as getFundDetailsActionTypes from "__pages/FundDetails/__redux/actionTypes";
import { ResponseGeneratorType, TaskGeneratorType } from "__utils/types";
import { call, cancel, cancelled, fork, put, select, take, takeLatest, takeEvery } from "redux-saga/effects";
import { GetFundDetailsType } from "__pages/FundDetails/__redux/types";
import { getFundDetailsSlr } from "__pages/FundDetails/__redux/selectors";
import { userProfileSlr } from "__pages/User/__redux/selectors";
import { UserProfileType } from "__pages/User/__redux/types";
import { isUserAdmin } from "__utils/helpers";
import fileDownload from "js-file-download";
import * as actionTypes from "./actionTypes";
import * as api from "../__api";

import {
	FundDetailsType,
	FundsType,
	GetFundsType,
	GetRequestSummaryType,
	IFundsById,
	IGetFundsUserMeta,
} from "./types";
import { addCustomPropsProps, buildFundsById, bubbleFavourites, addUserMeta } from "../__utils/helpers";
import { fundsSlr, getRequestSummarySlr, getFundsUserMetaSlr } from "./selectors";

const getFunds = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getFundsApi);
		const { status, data } = response;

		if (status === 200) {
			const getFunds: GetFundsType = yield select(fundsSlr);
			const { isOnPoll } = getFunds;
			const { brands, funds: newFunds } = data;

			let funds: FundsType = newFunds;
			if (isOnPoll) {
				const { requestStatusesData }: GetRequestSummaryType = yield select(getRequestSummarySlr);
				const { fundUserMeta }: IGetFundsUserMeta = yield select(getFundsUserMetaSlr);
				// Add user meta
				funds = addUserMeta(newFunds, fundUserMeta);
				// Add requests, alert, and string version of state props to funds
				funds = addCustomPropsProps(funds, requestStatusesData);
			}

			// Funds by ID
			const byId: IFundsById = buildFundsById(funds);

			yield put({
				type: actionTypes.GET_FUNDS_SUCCESS,
				byId,
				brands,
				funds: newFunds,
				isOnPoll: false,
			});

			const { level }: UserProfileType = yield select(userProfileSlr);
			if (isUserAdmin(level)) {
				yield put({
					type: actionTypes.GET_REQUEST_SUMMARY,
				});
				yield put({
					type: actionTypes.GET_FUND_USER_META,
				});
			}
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_FUNDS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_FUNDS_FAILURE });
	} finally {
		const isCancelled: ResponseGeneratorType = yield cancelled();
		if (isCancelled) {
			yield put({ type: actionTypes.GET_FUNDS_RESET });
		}
	}
};

export const getFundsSaga = function* (): any {
	while (true) {
		const getAction: ResponseGeneratorType = yield take(actionTypes.GET_FUNDS);
		const task: Array<TaskGeneratorType> = yield fork(getFunds);
		const action: ResponseGeneratorType = yield take([
			actionTypes.GET_FUNDS_SUCCESS,
			actionTypes.GET_FUNDS_NOOP,
			actionTypes.GET_FUNDS_CANCEL,
			actionTypes.GET_FUNDS_FAILURE,
		]);

		if (action.type === actionTypes.GET_FUNDS_CANCEL) {
			yield cancel(task);
		}
	}
};

const deleteFund = function* (action: any): any {
	try {
		const { payload } = action;
		const response: ResponseGeneratorType = yield call(api.deleteFundApi, payload);
		const { status } = response;
		if (status === 204) {
			// Remove preview data
			yield put({
				type: getFundDetailsActionTypes.GET_FUND_DETAILS_RESET,
			});

			// Update Delete funds
			yield put({
				type: actionTypes.DELETE_FUND_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.DELETE_FUND_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.DELETE_FUND_FAILURE });
	}
};

export const deleteFundSaga = function* (): any {
	yield takeLatest(actionTypes.DELETE_FUND, deleteFund);
};

const archiveFund = function* (action: any): any {
	try {
		const { payload } = action;
		const response: ResponseGeneratorType = yield call(api.archiveFundApi, payload);
		const { status } = response;

		if (status === 204) {
			const getFundDetails: GetFundDetailsType = yield select(getFundDetailsSlr);
			// Save new state
			yield put({
				type: getFundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				...getFundDetails,
				account: { ...getFundDetails.account, status: "Archived" },
			});

			// Update archive state
			yield put({
				type: actionTypes.ARCHIVE_FUND_SUCCESS,
				isArchiving: false,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.ARCHIVE_FUND_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.ARCHIVE_FUND_FAILURE });
	}
};

export const archiveFundSaga = function* (): any {
	yield takeLatest(actionTypes.ARCHIVE_FUND, archiveFund);
};

const restoreFund = function* (action: any): any {
	try {
		const { payload } = action;
		const response: ResponseGeneratorType = yield call(api.restoreFundApi, payload);
		const { status } = response;

		if (status === 204) {
			const getFundDetails: GetFundDetailsType = yield select(getFundDetailsSlr);
			// Save new state
			yield put({
				type: getFundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				...getFundDetails,
				account: { ...getFundDetails.account, status: "Active" },
			});

			// Update archive state
			yield put({
				type: actionTypes.RESTORE_FUND_SUCCESS,
				isRestoring: false,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.RESTORE_FUND_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.RESTORE_FUND_FAILURE });
	}
};

export const restoreFundSaga = function* (): any {
	yield takeLatest(actionTypes.RESTORE_FUND, restoreFund);
};

const deactivateFund = function* (action: any): any {
	try {
		const { payload } = action;
		const response: ResponseGeneratorType = yield call(api.deactivateFundDetailsApi, payload);
		const { status } = response;

		if (status === 204) {
			const getFundDetails: GetFundDetailsType = yield select(getFundDetailsSlr);
			// Save new state
			yield put({
				type: getFundDetailsActionTypes.GET_FUND_DETAILS_SUCCESS,
				...getFundDetails,
				account: { ...getFundDetails.account, status: "Inactive" },
			});

			// Update archive state
			yield put({
				type: actionTypes.DEACTIVATE_FUND_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.DEACTIVATE_FUND_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.DEACTIVATE_FUND_FAILURE });
	}
};

export const deactivateFundSaga = function* (): any {
	yield takeLatest(actionTypes.DEACTIVATE_FUND, deactivateFund);
};

const favouriteFund = function* (action: any): any {
	try {
		const { id, isFavourite } = action.payload;
		const response: ResponseGeneratorType = yield call(api.favouriteFundApi, action.payload);
		const { status } = response;

		if (status === 200) {
			const getFunds: GetFundsType = yield select(fundsSlr);
			const { funds } = getFunds;
			const updatedFunds: FundsType = funds.reduce((acc: FundsType, curr: FundDetailsType) => {
				if (curr.id === id) {
					const fund = { ...curr, favourite: isFavourite };
					acc.push(fund);
				} else {
					acc.push(curr);
				}

				return acc;
			}, []);

			// Save state
			yield put({
				type: actionTypes.GET_FUNDS_SUCCESS,
				...getFunds,
				funds: bubbleFavourites(updatedFunds),
			});

			yield put({
				type: actionTypes.GET_FUNDS,
				isOnPoll: true,
			});

			// Update own state
			yield put({
				type: actionTypes.FAVORITE_FUND_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.FAVORITE_FUND_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.FAVORITE_FUND_FAILURE });
	}
};

export const favouriteFundSaga = function* (): any {
	yield takeLatest(actionTypes.FAVORITE_FUND, favouriteFund);
};

const getRequestSummary = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getRequestSummaryApi);
		const {
			status,
			data: { hash, requests },
		} = response;

		if (status === 200) {
			const {
				requestStatusesData: { hash: oldHash },
			}: GetRequestSummaryType = yield select(getRequestSummarySlr);

			const requestStatusesData = {
				hash,
				oldHash,
				requestStatuses: requests,
			};

			// Save state
			yield put({
				type: actionTypes.GET_REQUEST_SUMMARY_SUCCESS,
				requestStatusesData,
				isGettingRequestSummary: false,
				isGetRequestSummarySuccess: true,
				isGetRequestSummaryError: false,
			});

			const { fundUserMeta }: IGetFundsUserMeta = yield select(getFundsUserMetaSlr);
			const getFunds: GetFundsType = yield select(fundsSlr);
			const { brands, funds, isOnPoll } = getFunds;

			if (!isOnPoll) {
				// Add user meta
				const withUserMeta: FundsType = addUserMeta(funds, fundUserMeta);
				// Add requests, alert, and string version of state props to funds
				const withCustomProps: FundsType = addCustomPropsProps(withUserMeta, requestStatusesData);
				// Funds by ID
				const byId: IFundsById = buildFundsById(withCustomProps);

				yield put({
					type: actionTypes.GET_FUNDS_SUCCESS,
					byId,
					brands,
					funds: withCustomProps,
					isOnPoll: false,
				});
			}
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_REQUEST_SUMMARY_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_REQUEST_SUMMARY_FAILURE });
	}
};

export const getRequestSummarySaga = function* (): any {
	while (true) {
		const getAction: ResponseGeneratorType = yield take(actionTypes.GET_REQUEST_SUMMARY);
		const task: Array<TaskGeneratorType> = yield fork(getRequestSummary);
		const action: ResponseGeneratorType = yield take([
			actionTypes.GET_REQUEST_SUMMARY_SUCCESS,
			actionTypes.GET_REQUEST_SUMMARY_CANCEL,
			actionTypes.GET_REQUEST_SUMMARY_FAILURE,
		]);

		if (action.type === actionTypes.GET_REQUEST_SUMMARY_CANCEL) {
			yield cancel(task);
		}
	}
};

const getSMSFComplyingFundsCount = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getSMSFComplyingFundsCountApi);
		const { status, data } = response;

		if (status === 200) {
			// Save state
			yield put({
				type: actionTypes.GET_SMSF_COMPLYING_FUNDS_COUNT_SUCCESS,
				...data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_SMSF_COMPLYING_FUNDS_COUNT_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_SMSF_COMPLYING_FUNDS_COUNT_FAILURE });
	}
};

export const getSMSFComplyingFundsCountSaga = function* (): any {
	yield takeLatest(actionTypes.GET_SMSF_COMPLYING_FUNDS_COUNT, getSMSFComplyingFundsCount);
};

const getFundsUserMeta = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getFundsUserMetaApi);
		const { status, data } = response;

		if (status === 200) {
			// Save state
			yield put({
				type: actionTypes.GET_FUND_USER_META_SUCCESS,
				fundUserMeta: data,
			});

			const { requestStatusesData }: GetRequestSummaryType = yield select(getRequestSummarySlr);
			const getFunds: GetFundsType = yield select(fundsSlr);
			const { brands, funds, isOnPoll } = getFunds;

			if (!isOnPoll) {
				// Add user meta
				const withUserMeta: FundsType = addUserMeta(funds, data);
				// Add requests, alert, and string version of state props to funds
				const withCustomProps: FundsType = addCustomPropsProps(withUserMeta, requestStatusesData);
				// Funds by ID
				const byId: IFundsById = buildFundsById(withCustomProps);

				yield put({
					type: actionTypes.GET_FUNDS_SUCCESS,
					byId,
					brands,
					funds: withCustomProps,
					isOnPoll: false,
				});
			}
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_FUND_USER_META_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_FUND_USER_META_FAILURE });
	}
};

export const getFundsUserMetaSaga = function* (): any {
	yield takeLatest(actionTypes.GET_FUND_USER_META, getFundsUserMeta);
};

// Get TAGS

const getTags = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getTagsApi);
		const { status, data } = response;

		if (status === 200) {
			// Save tags
			yield put({
				type: actionTypes.GET_TAGS_SUCCESS,
				tagList: data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.GET_TAGS_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_TAGS_FAILURE });
	}
};

export const getTagsSaga = function* (): any {
	yield takeLatest(actionTypes.GET_TAGS, getTags);
};

const extractFilename = (raw: string): string => raw.split(" ")[1].split('"')[1];

const downloadClientList = function* (action: any): any {
	try {
		const response: ResponseGeneratorType = yield call(api.downloadClientListApi);
		const { status, data } = response;
		yield call(fileDownload, data, extractFilename(response.headers["content-disposition"]));

		if (status === 200) {
			yield put({
				type: actionTypes.DOWNLOAD_CLIENT_LIST_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(process.env.REACT_APP_APP_URL as string);
		} else {
			yield put({ type: actionTypes.DOWNLOAD_CLIENT_LIST_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.DOWNLOAD_CLIENT_LIST_FAILURE });
	}
};

export const downloadClientListSaga = function* (): any {
	yield takeEvery(actionTypes.DOWNLOAD_CLIENT_LIST, downloadClientList);
};
