import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

import {
	DeleteDocumentTreeFileActionTypes,
	DeleteDocumentTreeFileType,
	DownloadDocumentActionTypes,
	DownloadDocumentType,
	GetDocumentTreeActionTypes,
	GetDocumentTreeType,
	deleteDocumentTreeFileDefaultValue,
	downloadDocumentDefaultValue,
	getDocumentTreeDefaultValue,
	getDocsFolderDetailsDefaultValue,
	GetDocsFolderDetailsType,
	GetDocsFolderDetailsActionTypes,
	updateDocumentDefaultValue,
	UpdateDocumentActionTypes,
	UpdateDocumentType,
} from "./types";

export const getDocumentTreeReducer = (
	state = getDocumentTreeDefaultValue,
	action: GetDocumentTreeActionTypes
): GetDocumentTreeType => {
	switch (action.type) {
		case actionTypes.GET_DOCUMENT_TREE:
			return {
				...state,
				isGettingDocumentTree: true,
			};
		case actionTypes.GET_DOCUMENT_TREE_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingDocumentTree: false,
				isGetDocumentTreeSuccess: true,
				isGetDocumentTreeError: false,
			};
		case actionTypes.GET_DOCUMENT_TREE_FAILURE:
			return {
				...state,
				isGettingDocumentTree: false,
				isGetDocumentTreeSuccess: false,
				isGetDocumentTreeError: true,
			};
		case actionTypes.GET_DOCUMENT_TREE_RESET:
			return {
				...state,
				...getDocumentTreeDefaultValue,
			};
		default:
			return state;
	}
};

export const deleteDocumentTreeFileReducer = (
	state = deleteDocumentTreeFileDefaultValue,
	action: DeleteDocumentTreeFileActionTypes
): DeleteDocumentTreeFileType => {
	switch (action.type) {
		case actionTypes.DELETE_DOCUMENT_TREE_FILE:
			return {
				...state,
				fileKey: (action as any).payload.key,
				isDeletingDocumentTreeFile: true,
			};

		case actionTypes.DELETE_DOCUMENT_TREE_FILE_SUCESS:
			return {
				...state,
				isDeleteDocumentTreeFileError: false,
				isDeleteDocumentTreeFileSuccess: true,
				isDeletingDocumentTreeFile: false,
			};
		case actionTypes.DELETE_DOCUMENT_TREE_FILE_FAILURE:
			return {
				...state,
				isDeletingDocumentTreeFile: false,
				isDeleteDocumentTreeFileError: true,
				isDeleteDocumentTreeFileSuccess: false,
			};

		case actionTypes.DELETE_DOCUMENT_TREE_FILE_RESET:
			return {
				...deleteDocumentTreeFileDefaultValue,
			};
		default:
			return state;
	}
};

export const downloadDocumentReducer = (
	state = downloadDocumentDefaultValue,
	action: DownloadDocumentActionTypes
): DownloadDocumentType => {
	switch (action.type) {
		case actionTypes.DOWNLOAD_DOCUMENT:
			return {
				...state,
				key: (action as any).payload.key,
				isDownloadingDocument: true,
			};
		case actionTypes.DOWNLOAD_DOCUMENT_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isDownloadingDocument: false,
				isDownloadDocumentSuccess: true,
				isDownloadDocumentError: false,
			};
		case actionTypes.DOWNLOAD_DOCUMENT_FAILURE:
			return {
				...state,
				isDownloadingDocument: false,
				isDownloadDocumentSuccess: false,
				isDownloadDocumentError: true,
			};
		case actionTypes.DOWNLOAD_DOCUMENT_RESET:
			return {
				...state,
				...downloadDocumentDefaultValue,
			};
		default:
			return state;
	}
};

export const getDocumentFolderDetailsReducer = (
	state = getDocsFolderDetailsDefaultValue,
	action: GetDocsFolderDetailsActionTypes
): GetDocsFolderDetailsType => {
	switch (action.type) {
		case actionTypes.GET_DOCS_FOLDER_DETAILS:
			return {
				...state,
				folderKey: (action as any).payload.folderKey,
				memberId: (action as any).payload.memberId,
				isGettingDocsFolderDetails: true,
			};

		case actionTypes.GET_DOCS_FOLDER_DETAILS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isGettingDocsFolderDetails: false,
				isGetDocsFolderDetailsSuccess: true,
				isGetDocsFolderDetailsError: false,
			};
		case actionTypes.GET_DOCS_FOLDER_DETAILS_FAILURE:
			return {
				...state,
				isGettingDocsFolderDetails: false,
				isGetDocsFolderDetailsSuccess: false,
				isGetDocsFolderDetailsError: true,
			};

		case actionTypes.GET_DOCS_FOLDER_DETAILS_RESET:
			return {
				...getDocsFolderDetailsDefaultValue,
			};
		default:
			return state;
	}
};

export const updateDocumentReducer = (
	state = updateDocumentDefaultValue,
	action: UpdateDocumentActionTypes
): UpdateDocumentType => {
	switch (action.type) {
		case actionTypes.UPDATE_DOCUMENT:
			return {
				...state,
				isUpdatingDocument: true,
			};

		case actionTypes.UPDATE_DOCUMENT_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				isUpdatingDocument: false,
				isUpdateDocumentSuccess: true,
				isUpdateDocumentError: false,
			};
		case actionTypes.UPDATE_DOCUMENT_FAILURE:
			return {
				...state,
				isUpdatingDocument: false,
				isUpdateDocumentSuccess: false,
				isUpdateDocumentError: true,
			};

		case actionTypes.UPDATE_DOCUMENT_RESET:
			return {
				...updateDocumentDefaultValue,
			};
		default:
			return state;
	}
};

export const fileManagerReducer = combineReducers({
	getDocumentTree: getDocumentTreeReducer,
	deleteDocumentTreeFile: deleteDocumentTreeFileReducer,
	downloadDocument: downloadDocumentReducer,
	getDocumentFolderDetails: getDocumentFolderDetailsReducer,
	updateDocument: updateDocumentReducer,
});
