import {
	AddAdminSaga,
	addBrandSaga,
	deleteAdminSaga,
	editAdminSaga,
	editBrandSaga,
	getAdminLevelsSaga,
	getBrandsOnScrollSaga,
	getBrandsSaga,
} from "__pages/Administration/__redux/sagas";
import {
	getDocumentsSaga as _getDocumentsSaga,
	addDocumentSaga,
	deleteDocumentSaga,
	editDocumentSaga,
	filterDocumentsSaga,
	getDocCategoriesSaga,
	getDocumentDetailsSaga,
	getDocumentsOnScrollSaga,
} from "__pages/RequestDocuments/__redux/sagas";
import {
	addAccountSaga,
	archiveFundDetailsSaga,
	deleteFundDetailsSaga,
	editAccountSaga,
	getLiveFundValueSaga,
	overrideLowBalanceFlagSaga,
	restoreFundDetailsSaga,
	deactivateFundDetailsSaga,
} from "__pages/FundDetails/__pages/Account/AccountDetails/PrimaryAccount/__redux/sagas";
import {
	addActivityNoteSaga,
	getActivitiesSaga,
} from "__pages/FundDetails/__pages/Account/ActivityDrawer/Activity/__redux/sagas";
import {
	addDocRequestSaga,
	completeDocRequestDocumentSaga,
	editDocRequestSaga,
	filterDocRequestsSaga,
	getDocRequestDetailsSaga,
	getDocRequestDocumentSaga,
	getDocRequestsOnScrollSaga,
	getDocRequestsSaga,
	getSigningRequestDetailsSaga,
	moveDocRequestStateSaga,
	remindDocRequestSaga,
	removeDocsFileSaga,
	removeDocumentActivitySaga,
	updateDocRequestDocumentFilesSaga,
	deleteSignedDocSaga,
} from "__pages/FundDetails/__pages/Requests/__redux/sagas";
import { addDocuSignEnvelopeSaga } from "__pages/FundDetails/__pages/Requests/RequestsToolBar/AddEnvelopModal/AddEnvelopeForm/__redux/sagas";
import {
	addFundStructureSaga,
	editFundStructureSaga,
} from "__pages/FundDetails/__pages/Account/Fund/FundStructure/__redux/sagas";
import {
	addMemberSaga,
	deleteMemberSaga,
	editMemberSaga,
	editDirectorIDSaga,
} from "__pages/FundDetails/__pages/Account/Fund/Members/__redux/sagas";
import {
	addTemplateSaga,
	deleteTemplateSaga,
	editTemplateSaga,
	filterTemplatesSaga,
	getTemplateCategoriesSaga,
	getTemplateDetailsSaga,
	getTemplatesOnScrollSaga,
	getTemplatesSaga,
} from "__pages/RequestTemplates/__redux/sagas";
import {
	addToWorkflowSaga,
	getWorkflowHistorySaga,
	getWorkflowSummarySaga,
	getWorkflowUpdateSaga,
	moveWorkflowStateSaga,
	overrideStateSaga,
} from "__common/Workflow/__redux/sagas";
import { all, fork } from "redux-saga/effects";
import {
	archiveFundSaga,
	deleteFundSaga,
	favouriteFundSaga,
	getFundsSaga,
	getRequestSummarySaga,
	restoreFundSaga,
	getSMSFComplyingFundsCountSaga,
	getFundsUserMetaSaga,
	getTagsSaga,
	deactivateFundSaga,
	downloadClientListSaga,
} from "__pages/Funds/__redux/sagas";
import { createFundSaga, getFundDetailsForModalSaga, getFundDetailsSaga } from "__pages/FundDetails/__redux/sagas";
import {
	deleteDocumentTreeFileSaga,
	downloadDocumentSaga,
	getDocumentTreeSaga,
	getDocumentFolderDetailsSaga,
	updateDocumentSaga,
} from "__pages/FundDetails/__pages/Documents/DocumentsList/FileManager/__redux/sagas";
import {
	downloadInvoiceSaga,
	getInvoicesSaga,
	updateInvoiceSaga,
} from "__pages/FundDetails/__pages/Billing/InvoicesList/__redux/sagas";
import { editClassSaga, getClassSaga } from "__pages/FundDetails/__pages/Account/AccountDetails/ClassID/__redux/sagas";
import {
	getFundStatesSaga,
	setNewStateSaga,
	updateItemInFundlistSaga,
} from "__pages/Funds/FundPreviewPane/StateProgress/__redux/sagas";
import { getUserProfileSaga, verifyUserAuthSaga } from "__pages/User/__redux/sagas";

import {
	addPaymentSaga,
	updatePaymentSaga,
} from "__pages/FundDetails/__pages/Billing/OneOffDebit/OneOffDebitForm/__redux/sagas";
import {
	addRecurringPaymentSaga,
	updateRecurringPaymentSaga,
} from "__pages/FundDetails/__pages/Billing/RecurringDebits/RecurringDebitsForm/__redux/sagas";
import { downloadReportSaga } from "__pages/FundDetails/__pages/Accounting/Reports/__redux/sagas";
import { editBankDetailsSaga } from "__pages/FundDetails/__pages/Account/AccountDetails/BankDetails/__redux/sagas";
import { editTagsSaga } from "__pages/Funds/FundPreviewPane/Account/Tags/AddRemoveTagsModal/__redux/sagas";
import {
	getWorkflowFundsSaga,
	moveWorkflowFundStateSaga,
	getWorkflowFundsUpdateSaga,
	addWorkflowFundNoteSaga,
	getWorkflowFundNotesSaga,
	completeWorkflowFundNoteSaga,
	updateWorkflowPersonSaga,
	getWorkflowsSaga,
} from "__pages/WorkflowDashboard/WorkflowFundList/__redux/sagas";
import {
	getReminderCountSaga,
	getReminderSaga,
	getReminderDetailsSaga,
	releaseReminderSaga,
	cancelReminderSaga,
} from "__pages/ManageReminders/__redux/sagas";
import { moveWorkflowFundDetailsGenerateDocsSaga } from "__redux/workflowFundDetails/sagas";
import { moveWorkflowDashboardSaga } from "__redux/workflowDashboard/sagas";
import {
	addTrustSaga,
	editTrustSaga,
	deleteTrustSaga,
} from "__pages/FundDetails/__pages/Account/AccountDetails/Trust/__redux/sagas";
import { getWorkflowChartDataSaga } from "__pages/WorkflowChart/__redux/sagas";

export default function* rootSaga(): any {
	yield all(
		[
			getUserProfileSaga,
			verifyUserAuthSaga,
			getDocumentTreeSaga,
			getFundsSaga,
			getFundDetailsSaga,
			getFundDetailsForModalSaga,
			createFundSaga,
			deleteFundSaga,
			deactivateFundSaga,
			archiveFundSaga,
			restoreFundSaga,
			favouriteFundSaga,
			addFundStructureSaga,
			editFundStructureSaga,
			addAccountSaga,
			editAccountSaga,
			editTagsSaga,
			editMemberSaga,
			editDirectorIDSaga,
			deleteMemberSaga,
			addMemberSaga,
			getInvoicesSaga,
			addPaymentSaga,
			addRecurringPaymentSaga,
			downloadInvoiceSaga,
			setNewStateSaga,
			getFundStatesSaga,
			updateItemInFundlistSaga,
			getTemplatesSaga,
			getTemplateCategoriesSaga,
			getDocumentDetailsSaga,
			editDocumentSaga,
			addDocumentSaga,
			getDocCategoriesSaga,
			_getDocumentsSaga,
			addTemplateSaga,
			getDocRequestsSaga,
			getDocRequestDetailsSaga,
			addDocRequestSaga,
			editDocRequestSaga,
			getTemplateDetailsSaga,
			editTemplateSaga,
			deleteDocumentSaga,
			deleteTemplateSaga,
			editBankDetailsSaga,
			getDocRequestDocumentSaga,
			completeDocRequestDocumentSaga,
			getRequestSummarySaga,
			archiveFundDetailsSaga,
			restoreFundDetailsSaga,
			deactivateFundDetailsSaga,
			deleteFundDetailsSaga,
			getClassSaga,
			editClassSaga,
			downloadReportSaga,
			updateDocRequestDocumentFilesSaga,
			deleteSignedDocSaga,
			moveDocRequestStateSaga,
			getDocumentsOnScrollSaga,
			filterDocumentsSaga,
			getTemplatesOnScrollSaga,
			filterTemplatesSaga,
			getDocRequestsOnScrollSaga,
			filterDocRequestsSaga,
			getAdminLevelsSaga,
			addBrandSaga,
			editBrandSaga,
			AddAdminSaga,
			editAdminSaga,
			deleteAdminSaga,
			getBrandsSaga,
			overrideLowBalanceFlagSaga,
			removeDocsFileSaga,
			getActivitiesSaga,
			addActivityNoteSaga,
			deleteDocumentTreeFileSaga,
			remindDocRequestSaga,
			removeDocumentActivitySaga,
			getLiveFundValueSaga,
			getBrandsOnScrollSaga,
			downloadDocumentSaga,
			addToWorkflowSaga,
			addDocuSignEnvelopeSaga,
			getWorkflowSummarySaga,
			getWorkflowUpdateSaga,
			moveWorkflowStateSaga,
			getSigningRequestDetailsSaga,
			getWorkflowHistorySaga,
			getWorkflowFundsSaga,
			moveWorkflowFundStateSaga,
			getWorkflowFundsUpdateSaga,
			addWorkflowFundNoteSaga,
			getWorkflowFundNotesSaga,
			completeWorkflowFundNoteSaga,
			getReminderCountSaga,
			getReminderSaga,
			getReminderDetailsSaga,
			updateWorkflowPersonSaga,
			releaseReminderSaga,
			cancelReminderSaga,
			getSMSFComplyingFundsCountSaga,
			moveWorkflowFundDetailsGenerateDocsSaga,
			moveWorkflowDashboardSaga,
			getFundsUserMetaSaga,
			overrideStateSaga,
			getWorkflowsSaga,
			getDocumentFolderDetailsSaga,
			updateDocumentSaga,
			getTagsSaga,
			addTrustSaga,
			editTrustSaga,
			deleteTrustSaga,
			downloadClientListSaga,
			getWorkflowChartDataSaga,
			updatePaymentSaga,
			updateRecurringPaymentSaga,
			updateInvoiceSaga,
		].map(fork)
	);
}
