import Button from "antd/es/button";
import Checkbox from "antd/es/checkbox";
import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Row from "antd/es/row";
import Select from "antd/es/select";
import { FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React from "react";
import { VscLocation } from "react-icons/vsc";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import { useMediaQuery } from "react-responsive";
import AntdifyIcon from "__common/AntdifyIcon";
import DatePicker from "__common/DatePicker";
import DropdownRender from "__common/DropdownRender";
import { FloatLabel } from "__common/FloatLabel";
import { GetFundDetailsType, MemberType } from "__pages/FundDetails/__redux/types";
import { BREAKPOINTS } from "__utils/constants";
import * as lang from "__utils/lang";
import { ObjectType } from "__utils/types";
import { isUserAdmin } from "__utils/helpers";
import { useSelector } from "react-redux";
import { userProfileSlr } from "__pages/User/__redux/selectors";
import { UserProfileType } from "__pages/User/__redux/types";
import { statesInitialName } from "../../__utils/constants";
import { getOccupations, getOccupationSubTypes, getPlaces, getSuggestionsStyle } from "../../__utils/helper";
import * as memberLang from "../../__utils/lang";
import CorporateFields from "./CorporateFields";
import PostalFields from "./PostalFields";
import { Container } from "./style";
import IdentificationFields from "./IdentificationFields";
import BusinessInformationFields from "./BusinessInformationFields";

const showPostalCheckbox = (members: Array<MemberType>, currMember: MemberType | undefined): boolean => {
	const currMemberIndex: number = members.findIndex((member: MemberType) => member.id === currMember?.id);

	return members.length === 0 || currMemberIndex === 0;
};

type Props = {
	fund: GetFundDetailsType;
	form: FormInstance;
	isLoading: boolean;
	isDeleting?: boolean;
	numOfMembers?: number;
	member?: MemberType;
	disableSubmitButton?: boolean;
	submitButtonLabel?: string;
	undoButtonLabel?: string;
	cancelButtonLabel?: string;
	deleteBUttonLabel?: string;
	onValuesChange?: (values: any) => void;
	onFinish?: (values: any) => void;
	onUndo?: () => void;
	onDelete?: (memberId: number) => void;
	onCancel?: () => void;
	isDisabled?: boolean;
};

const MemberForm: React.FC<Props> = ({
	fund,
	form,
	isLoading,
	isDeleting,
	numOfMembers,
	member,
	disableSubmitButton,
	submitButtonLabel,
	undoButtonLabel,
	cancelButtonLabel,
	deleteBUttonLabel,
	onValuesChange,
	onFinish,
	onUndo,
	onDelete,
	onCancel,
	isDisabled = false,
}: Props) => {
	const { getFieldValue, setFieldsValue } = form;
	const [occupationCategory, setOccupationCategory] = React.useState<string[]>([]);
	const [postalCheckBox, setPostalCheckBox] = React.useState<boolean>(false);
	const [residentialAutoAddress, setResidentialAddress] = React.useState<ObjectType>({});
	const [address, setAddress] = React.useState("");
	const occupationTypes = getOccupations();
	const { fundStructure, account, members, integration } = fund;
	const { email, phone, firstName, lastName } = account;
	const { inClass } = integration;
	const { fundType } = fundStructure;
	const { id, notUseResidentialAsMailing, title } = member || {};
	const initialValues = !member?.id ? {} : { ...member, dateOfBirth: dayjs(member?.dateOfBirth) };
	const isLG: boolean = useMediaQuery({ query: BREAKPOINTS.LG });
	const [occupationSelected, setOccupationSelected] = React.useState<string>("");
	const userProfile: UserProfileType = useSelector(userProfileSlr);
	const { level } = userProfile;
	const showIdentificationField = (isUserAdmin(level) && !isEmpty(member)) || isEmpty(member);
	const showBusinessInformationField =
		(isUserAdmin(level) &&
			!isEmpty(member) &&
			getFieldValue(memberLang.OCCUPATION_TYPE_NAME) === "Business Owner") ||
		occupationSelected === "Business Owner";

	const onOccupationTypeChange = (e: string): void => {
		setFieldsValue({ occupationCategory: "" });
		setOccupationSelected(e);
		setOccupationCategory(getOccupationSubTypes(e));
	};

	const onResidentialAsMailingChange = (): void => setPostalCheckBox((value) => !value);

	const onPlaceChange = async (value: string): Promise<void> => {
		const result = await geocodeByAddress(value);

		const getPlacesData: ObjectType = (await getPlaces(result)) as ObjectType;
		const { address, postCode, state, suburb, streetNumber, streetOnly } = getPlacesData || {};

		setResidentialAddress({
			...residentialAutoAddress,
			residentialAddress: address,
			residentialPostcode: postCode,
			residentialSuburb: suburb,
			residentialState: state,
			residentialStreetOnly: streetOnly,
			residentialStreetNumber: streetNumber,
		});
		setFieldsValue({
			residentialAddress: address,
			residentialPostcode: postCode,
			residentialSuburb: suburb,
			residentialState: state,
			residentialStreetOnly: streetOnly,
			residentialStreetNumber: streetNumber,
		});
	};

	const onAddressChange = (value: string): void => {
		setAddress(value);
	};

	React.useEffect(() => {
		if (!members.length) {
			setFieldsValue({
				email,
				phone,
				firstName,
				lastName,
			});
		}
	}, [members, email, phone, firstName, lastName, setFieldsValue]);

	React.useEffect(() => {
		if (!id) {
			setPostalCheckBox(false);
		} else {
			setPostalCheckBox(!!notUseResidentialAsMailing);
			setOccupationSelected(getFieldValue(memberLang.OCCUPATION_TYPE_NAME));
		}
	}, [notUseResidentialAsMailing, id, getFieldValue]);

	React.useEffect(() => {
		if (isEmpty(member)) {
			setFieldsValue({ ...residentialAutoAddress });
		}
	}, [residentialAutoAddress, setFieldsValue, member]);

	return (
		<Container isLG={isLG} isDisabled={isDisabled} hiddenFields={isEmpty(member)}>
			<Form form={form} initialValues={initialValues} onFinish={onFinish} onValuesChange={onValuesChange}>
				<div className="form-content">
					<Row gutter={[16, 8]}>
						<Col xs={24} xl={8}>
							<FloatLabel
								label={memberLang.TITLE_LABEL}
								getValue={() => getFieldValue(memberLang.TITLE_NAME)}
							>
								<Form.Item
									name={memberLang.TITLE_NAME}
									rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
								>
									<Select
										getPopupContainer={(trigger: any) => trigger.parentElement}
										dropdownRender={(menu: any) => <DropdownRender>{menu}</DropdownRender>}
										disabled={isDisabled}
										showAction={["click", "focus"]}
										autoFocus={!title}
									>
										<Select.Option value="Mr">Mr.</Select.Option>
										<Select.Option value="Ms">Ms.</Select.Option>
										<Select.Option value="Miss">Miss.</Select.Option>
										<Select.Option value="Mrs">Mrs.</Select.Option>
										<Select.Option value="Dr">Dr</Select.Option>
									</Select>
								</Form.Item>
							</FloatLabel>
						</Col>
					</Row>
					<Row gutter={[16, 8]}>
						<Col xs={24} xl={8}>
							<FloatLabel
								label={memberLang.FIRST_NAME_LABEL}
								getValue={() =>
									getFieldValue(memberLang.FIRST_NAME_NAME) || (members.length === 0 && firstName)
								}
							>
								<Form.Item
									name={memberLang.FIRST_NAME_NAME}
									rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
								>
									<Input disabled={numOfMembers === 0 || isDisabled} autoComplete="chrome-off" />
								</Form.Item>
							</FloatLabel>
						</Col>
						<Col xs={24} xl={8}>
							<FloatLabel
								label={memberLang.MIDDLE_LABEL}
								getValue={() => getFieldValue(memberLang.MIDDLE_NAME)}
							>
								<Form.Item name={memberLang.MIDDLE_NAME}>
									<Input disabled={isDisabled} autoComplete="chrome-off" />
								</Form.Item>
							</FloatLabel>
						</Col>
						<Col xs={24} xl={8}>
							<FloatLabel
								label={memberLang.LAST_NAME_LABEL}
								getValue={() =>
									getFieldValue(memberLang.LAST_NAME_NAME) || (members.length === 0 && lastName)
								}
							>
								<Form.Item
									name={memberLang.LAST_NAME_NAME}
									rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
								>
									<Input disabled={numOfMembers === 0 || isDisabled} autoComplete="chrome-off" />
								</Form.Item>
							</FloatLabel>
						</Col>
					</Row>

					<Row gutter={[16, 8]}>
						<Col xs={24} xl={8}>
							<FloatLabel
								label={memberLang.EMAIL_LABEL}
								getValue={() => getFieldValue(memberLang.EMAIL_NAME) || (members.length === 0 && email)}
							>
								<Form.Item
									name={memberLang.EMAIL_NAME}
									rules={[{ required: true, type: "email", message: lang.REQUIRED_FIELD_ERROR }]}
								>
									<Input disabled={numOfMembers === 0 || isDisabled} autoComplete="chrome-off" />
								</Form.Item>
							</FloatLabel>
						</Col>
						<Col xs={24} xl={8}>
							<FloatLabel
								label={memberLang.PHONE_LABEL}
								getValue={() => getFieldValue(memberLang.PHONE_NAME) || (members.length === 0 && phone)}
							>
								<Form.Item
									name={memberLang.PHONE_NAME}
									rules={[
										{ required: true, message: lang.REQUIRED_FIELD_ERROR },
										{ max: 10, message: memberLang.PHONE_MAX_LEN_ERROR },
										({ getFieldValue }) => ({
											validator(_, value) {
												const getDirectorIdValue = getFieldValue(memberLang.PHONE_NAME);
												if (getDirectorIdValue) {
													const isValidPhoneNum = getDirectorIdValue.startsWith("04");
													if (!value || isValidPhoneNum) {
														return Promise.resolve();
													}
													return Promise.reject(
														new Error("Phone Number should start with 04")
													);
												}
												return Promise.resolve();
											},
										}),
									]}
								>
									<Input
										disabled={numOfMembers === 0 || isDisabled}
										autoComplete="chrome-off"
										maxLength={10}
									/>
								</Form.Item>
							</FloatLabel>
						</Col>
						<Col xs={24} xl={8}>
							<FloatLabel
								label={memberLang.DOB_LABEL}
								getValue={() => getFieldValue(memberLang.DOB_NAME)}
							>
								<Form.Item
									name={memberLang.DOB_NAME}
									rules={[
										{
											required: true,
											message: lang.REQUIRED_FIELD_ERROR,
										},
									]}
								>
									<DatePicker
										placeholder=""
										format="DD-MM-YYYY"
										disabled={isDisabled}
										autoComplete="chrome-off"
										disabledDate={(currDateList) => {
											const currDate = dayjs().format("YYYY-MM-DD");
											return currDateList && currDateList > dayjs(currDate, "YYYY-MM-DD");
										}}
									/>
								</Form.Item>
							</FloatLabel>
						</Col>
					</Row>
					{showIdentificationField && !inClass && (
						<IdentificationFields form={form} isDisabled={isDisabled} />
					)}
					{fundType === "Corporate" && <CorporateFields form={form} isDisabled={isDisabled} />}

					<Row gutter={[16, 8]}>
						<Col xs={24} xl={8}>
							<FloatLabel
								label={memberLang.TFN_LABEL}
								getValue={() => getFieldValue(memberLang.TFN_NAME)}
							>
								<Form.Item
									name={memberLang.TFN_NAME}
									rules={[
										{ required: true, message: lang.REQUIRED_FIELD_ERROR },
										{
											pattern: /^(?:\d*)$/,
											message: "Value should contain just number",
										},
										{
											max: 9,
											message: "Value should be less than 9 character",
										},
									]}
								>
									<Input disabled={isDisabled} autoComplete="chrome-off" />
								</Form.Item>
							</FloatLabel>
						</Col>

						<Col xs={24} xl={8}>
							<FloatLabel
								label={memberLang.OCCUPATION_TYPE_LABEL}
								getValue={() => getFieldValue(memberLang.OCCUPATION_TYPE_NAME)}
							>
								<Form.Item
									name={memberLang.OCCUPATION_TYPE_NAME}
									rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
								>
									<Select
										getPopupContainer={(trigger: any) => trigger.parentElement}
										dropdownRender={(menu: any) => <DropdownRender>{menu}</DropdownRender>}
										onChange={onOccupationTypeChange}
										disabled={isDisabled}
										showAction={["click", "focus"]}
										showSearch
									>
										{occupationTypes.map((type, idx) => (
											<Select.Option key={idx} value={type}>
												{type}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</FloatLabel>
						</Col>
						<Col xs={24} xl={8}>
							<FloatLabel
								label={memberLang.OCCUPATION_CATEGORY_LABEL}
								getValue={() => getFieldValue(memberLang.OCCUPATION_CATEGORY_NAME)}
							>
								<Form.Item
									name={memberLang.OCCUPATION_CATEGORY_NAME}
									rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
								>
									<Select
										getPopupContainer={(trigger: any) => trigger.parentElement}
										dropdownRender={(menu: any) => <DropdownRender>{menu}</DropdownRender>}
										disabled={isDisabled}
										showAction={["click", "focus"]}
										showSearch
									>
										{isEmpty(occupationCategory) ? (
											<Select.Option value="none" disabled>
												Chose Occupation Type First
											</Select.Option>
										) : (
											occupationCategory.map((values, idx) => (
												<Select.Option value={values} key={idx}>
													{values}
												</Select.Option>
											))
										)}
									</Select>
								</Form.Item>
							</FloatLabel>
						</Col>
					</Row>

					{showBusinessInformationField && <BusinessInformationFields form={form} isDisabled={isDisabled} />}
					<Row gutter={[16, 8]}>
						<Col xs={24} xl={8}>
							<FloatLabel
								label={memberLang.RESIDENTIAL_ADDRESS_LABEL}
								getValue={() => getFieldValue(memberLang.RESIDENTIAL_ADDRESS_NAME)}
							>
								<PlacesAutocomplete
									value={address}
									onChange={onAddressChange}
									onSelect={onPlaceChange}
									// use to search AUS Only
									searchOptions={{ componentRestrictions: { country: "aus" } }}
								>
									{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
										<div>
											<Form.Item
												name={memberLang.RESIDENTIAL_ADDRESS_NAME}
												rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
											>
												<Input
													{...getInputProps()}
													disabled={isDisabled}
													autoComplete="chrome-off"
												/>
											</Form.Item>

											{loading && <div>Loading...</div>}
											{suggestions.length > 0 && (
												<div className="suggestion-list">
													{suggestions.map((suggestion, idx) => {
														const className = "suggestion-item";
														const style = getSuggestionsStyle(suggestion.active);

														return (
															<div
																{...getSuggestionItemProps(suggestion, {
																	className,
																	style,
																})}
																key={idx}
															>
																<AntdifyIcon
																	ariaLabel="location icon"
																	style={{ fontSize: "16px" }}
																>
																	<VscLocation />
																</AntdifyIcon>{" "}
																<strong>
																	{suggestion.formattedSuggestion.mainText}
																</strong>{" "}
																<small>
																	{suggestion.formattedSuggestion.secondaryText}
																</small>
															</div>
														);
													})}
												</div>
											)}
										</div>
									)}
								</PlacesAutocomplete>
							</FloatLabel>
						</Col>
						<Col xs={24} xl={8}>
							<Row gutter={[8, 4]}>
								<Col xs={24} xl={12}>
									<FloatLabel
										label={memberLang.UNIT_LABEL}
										getValue={() => getFieldValue(memberLang.UNIT_NAME)}
									>
										<Form.Item name={memberLang.UNIT_NAME}>
											<Input disabled={isDisabled} autoComplete="chrome-off" />
										</Form.Item>
									</FloatLabel>
								</Col>
								<Col xs={24} xl={12}>
									<FloatLabel
										label={memberLang.SUBURB_LABEL}
										getValue={() =>
											getFieldValue(memberLang.SUBURB_NAME) ||
											residentialAutoAddress.residentialSuburb
										}
									>
										<Form.Item
											name={memberLang.SUBURB_NAME}
											rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
										>
											<Input disabled={isDisabled} autoComplete="chrome-off" />
										</Form.Item>
									</FloatLabel>
								</Col>
							</Row>
						</Col>
						<Col xs={24} xl={8}>
							<Row gutter={[8, 4]}>
								<Col xs={24} xl={12}>
									<FloatLabel
										label={memberLang.STATE_LABEL}
										getValue={() =>
											getFieldValue(memberLang.STATE_NAME) ||
											residentialAutoAddress.residentialState
										}
									>
										<Form.Item
											name={memberLang.STATE_NAME}
											rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
										>
											<Select
												getPopupContainer={(trigger: any) => trigger.parentElement}
												dropdownRender={(menu: any) => <DropdownRender>{menu}</DropdownRender>}
												disabled={isDisabled}
												showAction={["click", "focus"]}
											>
												{statesInitialName.map((type, idx) => (
													<Select.Option key={idx} value={type}>
														{type}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</FloatLabel>
								</Col>
								<Col xs={24} xl={12}>
									<FloatLabel
										label={memberLang.POSTCODE_LABEL}
										getValue={() =>
											getFieldValue(memberLang.POSTCODE_NAME) ||
											residentialAutoAddress.residentialPostcode
										}
									>
										<Form.Item
											name={memberLang.POSTCODE_NAME}
											rules={[{ required: true, message: lang.REQUIRED_FIELD_ERROR }]}
										>
											<Input disabled={isDisabled} autoComplete="chrome-off" />
										</Form.Item>
									</FloatLabel>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row gutter={[16, 8]} className="hidden-address-fields">
						<Col xs={24} xl={8}>
							<Row gutter={[8, 4]}>
								<Col span={12}>
									<FloatLabel
										label={memberLang.STREET_NUMBER_LABEL}
										getValue={() =>
											getFieldValue(memberLang.STREET_NUMBER) ||
											residentialAutoAddress.residentialStreetNumber
										}
									>
										<Form.Item name={memberLang.STREET_NUMBER}>
											<Input disabled={isDisabled} />
										</Form.Item>
									</FloatLabel>
								</Col>
								<Col span={12}>
									<FloatLabel
										label={memberLang.STREET_ONLY_LABEL}
										getValue={() =>
											getFieldValue(memberLang.STREET_ONLY) ||
											residentialAutoAddress.residentialStreetOnly
										}
									>
										<Form.Item name={memberLang.STREET_ONLY}>
											<Input disabled={isDisabled} />
										</Form.Item>
									</FloatLabel>
								</Col>
							</Row>
						</Col>
					</Row>

					{showPostalCheckbox(members, member) && (
						<Row gutter={[16, 8]}>
							<Col xs={24} xl={24}>
								<Form.Item name={memberLang.POSTAL_NAME} valuePropName="checked">
									<Checkbox onChange={onResidentialAsMailingChange} disabled={isDisabled}>
										Postal address is different to residential?
									</Checkbox>
								</Form.Item>
							</Col>
						</Row>
					)}

					{postalCheckBox && <PostalFields form={form} isDisabled={isDisabled} />}
				</div>
				{!isDisabled && (
					<div className="form-footer">
						{onCancel && <Button onClick={onCancel}>{cancelButtonLabel || "Cancel"}</Button>}

						{onDelete && (
							<Button
								onClick={() => onDelete(member?.id as number)}
								disabled={isDeleting}
								loading={isDeleting}
								danger
							>
								{deleteBUttonLabel || "Delete"}
							</Button>
						)}
						{onUndo && (
							<Button
								disabled={disableSubmitButton}
								onClick={() => {
									onUndo();
									setPostalCheckBox(!!notUseResidentialAsMailing);
								}}
							>
								{undoButtonLabel || "Undo"}
							</Button>
						)}
						{onFinish && (
							<Button
								type="primary"
								htmlType="submit"
								disabled={disableSubmitButton || isLoading}
								loading={isLoading}
							>
								{submitButtonLabel || "Save"}
							</Button>
						)}
					</div>
				)}
			</Form>
		</Container>
	);
};

export default MemberForm;
