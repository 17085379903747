import * as actionTypes from "./actionTypes";

/**
 * Get invoices
 */

export type InvoicesType = Array<InvoiceType>;

export type InvoiceType = {
	readonly id: number;
	readonly debitDate: string;
	readonly invoiceId: string;
	readonly paymentId: number;
	readonly amount: number;
	readonly status: string;
	readonly scheduleAmount: string;
	readonly description: string;
};

export type GetInvoicesType = {
	readonly invoices: InvoicesType;
	readonly isGettingInvoices: boolean;
	readonly isGetInvoicesSuccess: boolean;
	readonly isGetInvoicesError: boolean;
};

export const getInvoicesDefaultValue: GetInvoicesType = {
	invoices: [],
	isGettingInvoices: false,
	isGetInvoicesSuccess: false,
	isGetInvoicesError: false,
};

export type GetInvoicesPayloadType = {
	readonly id: number;
};

export type GetInvoices = {
	(payload: GetInvoicesPayloadType): GetInvoicesActionTypes;
};

type GetInvoicesAction = {
	type: typeof actionTypes.GET_INVOICES;
	payload: GetInvoicesPayloadType;
};

type GetInvoicesSuccessAction = {
	type: typeof actionTypes.GET_INVOICES_SUCCESS;
};

type GetInvoicesFailureAction = {
	type: typeof actionTypes.GET_INVOICES_FAILURE;
};

export type GetInvoicesActionTypes = GetInvoicesAction | GetInvoicesSuccessAction | GetInvoicesFailureAction;

/**
 * Download invoice
 */

export type DownloadInvoiceType = {
	readonly index: number;
	readonly isDownloadingInvoice: boolean;
	readonly isDownloadInvoiceSuccess: boolean;
	readonly isDownloadInvoiceError: boolean;
	readonly downloadInvoiceError: string;
};

export const downloadInvoiceDefaultValue: DownloadInvoiceType = {
	index: -1,
	isDownloadingInvoice: false,
	isDownloadInvoiceSuccess: false,
	isDownloadInvoiceError: false,
	downloadInvoiceError: "",
};

export type DownloadInvoicePayloadType = {
	readonly id: number;
	readonly paymentId: number;
};

export type DownloadInvoice = {
	(payload: DownloadInvoicePayloadType): GetInvoicesActionTypes;
};

type DownloadInvoiceAction = {
	type: typeof actionTypes.DOWNLOAD_INVOICE;
	payload: DownloadInvoicePayloadType;
};

type DownloadInvoiceSuccessAction = {
	type: typeof actionTypes.DOWNLOAD_INVOICE_SUCCESS;
	payload: DownloadInvoicePayloadType;
};

type DownloadInvoiceFailureAction = {
	type: typeof actionTypes.DOWNLOAD_INVOICE_FAILURE;
	payload: DownloadInvoicePayloadType;
};

export type DownloadInvoiceActionTypes =
	| DownloadInvoiceAction
	| DownloadInvoiceSuccessAction
	| DownloadInvoiceFailureAction;

/**
 * Update invoice
 */

export type IUpdateInvoice = {
	readonly index: number;
	readonly isUpdatingInvoice: boolean;
	readonly isUpdateInvoiceSuccess: boolean;
	readonly isUpdateInvoiceError: boolean;
	readonly updateInvoiceError: string;
};

export const updateInvoiceDefaultValue: IUpdateInvoice = {
	index: -1,
	isUpdatingInvoice: false,
	isUpdateInvoiceSuccess: false,
	isUpdateInvoiceError: false,
	updateInvoiceError: "",
};

export type IUpdateInvoicePayload = {
	readonly id: number;
	readonly paymentId: number;
	readonly description: string;
};

type IUpdateInvoiceAction = {
	type: typeof actionTypes.UPDATE_INVOICE;
	payload: IUpdateInvoicePayload;
};

type IUpdateInvoiceSuccessAction = {
	type: typeof actionTypes.UPDATE_INVOICE_SUCCESS;
	payload: IUpdateInvoicePayload;
};

type IUpdateInvoiceFailureAction = {
	type: typeof actionTypes.UPDATE_INVOICE_FAILURE;
};

type IUpdateInvoiceResetAction = {
	type: typeof actionTypes.UPDATE_INVOICE_RESET;
};

export type IUpdateInvoiceActions =
	| IUpdateInvoiceResetAction
	| IUpdateInvoiceAction
	| IUpdateInvoiceSuccessAction
	| IUpdateInvoiceFailureAction;
