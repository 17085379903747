import { ApiErrorType } from "__utils/types";
import * as actionTypes from "./actionTypes";
/**
 * Create Payment
 */

export type AddPaymentType = {
	readonly isAddingPayment: boolean;
	readonly isAddPaymentSuccess: boolean;
	readonly isAddPaymentError: boolean;
	readonly addPaymentError: string | ApiErrorType;
};

export const addPaymentDefaultValue: AddPaymentType = {
	isAddingPayment: false,
	isAddPaymentSuccess: false,
	isAddPaymentError: false,
	addPaymentError: {},
};

export type AddPaymentPayloadType = {
	readonly id: number;
	readonly debitDate: string;
	readonly amount: string;
	readonly reference: string;
	readonly description: string;
};

type AddPaymentAction = {
	type: typeof actionTypes.ADD_PAYMENT;
	payload: AddPaymentPayloadType;
};

type AddPaymentSuccessAction = {
	type: typeof actionTypes.ADD_PAYMENT_SUCCESS;
};

type AddPaymentFailureAction = {
	type: typeof actionTypes.ADD_PAYMENT_FAILURE;
};

type AddPaymentResetAction = {
	type: typeof actionTypes.ADD_PAYMENT_RESET;
};

export type AddPaymentActionTypes =
	| AddPaymentAction
	| AddPaymentSuccessAction
	| AddPaymentFailureAction
	| AddPaymentResetAction;

// Update

export type IUpdatePayment = {
	readonly isUpdatingPayment: boolean;
	readonly isUpdatePaymentSuccess: boolean;
	readonly isUpdatePaymentError: boolean;
	readonly updatePaymentError: string | ApiErrorType;
};

export const updatePaymentDefaultValue: IUpdatePayment = {
	isUpdatingPayment: false,
	isUpdatePaymentSuccess: false,
	isUpdatePaymentError: false,
	updatePaymentError: {},
};

export type IUpdatePaymentPayload = {
	readonly paymentId: number;
	readonly memberId: number;
	readonly debitDate: string;
	readonly amount: string;
	readonly reference: string;
	readonly description: string;
};

type IUpdatePaymentAction = {
	type: typeof actionTypes.UPDATE_PAYMENT;
	payload: IUpdatePaymentPayload;
};

type IUpdatePaymentSuccessAction = {
	type: typeof actionTypes.UPDATE_PAYMENT_SUCCESS;
};

type IUpdatePaymentFailureAction = {
	type: typeof actionTypes.UPDATE_PAYMENT_FAILURE;
};

type IUpdatePaymentResetAction = {
	type: typeof actionTypes.UPDATE_PAYMENT_RESET;
};

export type IUpdatePaymentActions =
	| IUpdatePaymentAction
	| IUpdatePaymentSuccessAction
	| IUpdatePaymentFailureAction
	| IUpdatePaymentResetAction;
