import { ObjectType } from "__utils/types";
import { TagsType } from "__pages/Funds/FundPreviewPane/Account/Tags/AddRemoveTagsModal/__redux/types";
import { IStatus } from "__pages/Funds/__redux/types";
import * as actionTypes from "./actionTypes";

/**
 * Fund
 */

export type AccountType = {
	readonly id: number;
	readonly firstName: string;
	readonly lastName: string;
	readonly email: string;
	readonly phone: string;
	readonly status: IStatus;
	readonly dateJoined: string;
	readonly lastLogin: string;
	readonly isVerified: boolean;
	readonly state: string;
	readonly administratorBrand: number | string;
	readonly tags: TagsType;
	readonly fundValue: number;
};

export type StructureType = {
	readonly fundOrigin: string;
	readonly fundType: string;
	readonly fundName: string;
	readonly fundCodeName: string;
	readonly jurisdiction: string;
	readonly companyName?: string;
	readonly abn?: string;
	readonly acn?: string;
	readonly tfn?: string;
	readonly engagementUrlHash?: string;
	readonly lowValueFund: boolean;
};

export type MemberType = {
	readonly id: number;
	readonly title: string;
	readonly firstName: string;
	readonly lastName: string;
	readonly middleName: string;
	readonly email: string;
	readonly phone: string;
	readonly dateOfBirth: number;
	readonly tfn: string;
	readonly occupationType: string;
	readonly occupationCategory: string;
	readonly residentialStreetNumber: string;
	readonly residentialStreetOnly: string;
	readonly residentialUnit: number;
	readonly residentialAddress: string;
	readonly residentialSuburb: string;
	readonly residentialState: string;
	readonly residentialPostcode: string;
	readonly postalUnit?: string;
	readonly postalAddress?: string;
	readonly postalSuburb?: string;
	readonly postalState?: string;
	readonly postalPostcode?: string;
	readonly countryOfBirth?: string;
	readonly stateOfBirth?: string;
	readonly cityOfBirth?: string;
	readonly notUseResidentialAsMailing?: boolean;
	readonly useResidentialAsMailing?: boolean;
	readonly identificationType?: string;
	readonly identificationNumber?: string;
	readonly identificationStateIssued?: string;
	readonly tradingName?: string;
	readonly businessRegistrationNumber?: string;
	readonly businessAddress?: string;
	readonly businessSuburb?: string;
	readonly businessState?: string;
	readonly businessPostcode?: string;
	readonly businessCountry?: string;
	readonly directorId?: string;
	readonly cardNumber?: string;
};

export type BankDetailsType = {
	readonly applicationId: string;
	readonly accountName: string;
	readonly bsb: string;
	readonly accountNumber: string;
} | null;

export type StateType = ObjectType;

export type IntegrationType = {
	readonly inClass: boolean;
	readonly classUrl: string;
	readonly inEzidebit: boolean;
	readonly hubspotId: number;
	readonly hubspotUrl: string;
};

export type IStatesStatus = { [key: string]: { status: string } };

export type ITrust = {
	id: number;
	trustName: string;
	trusteeCompanyName: string;
	trusteeCompanyAcn: string;
	trustType: string;
};

export type GetFundDetailsType = {
	readonly account: AccountType;
	readonly fundStructure: StructureType;
	readonly members: Array<MemberType>;
	readonly bankDetails: BankDetailsType;
	readonly integration: IntegrationType;
	readonly statesStatus: IStatesStatus;
	readonly trust: ITrust[];
	readonly states: Array<StateType>;
	readonly isGetting: boolean;
	readonly isSuccess: boolean;
	readonly isError: boolean;
};

export const fundAccountDefaultValue: AccountType = {
	id: 0,
	firstName: "",
	lastName: "",
	email: "",
	phone: "",
	status: "Inactive",
	dateJoined: "",
	lastLogin: "",
	isVerified: false,
	state: "",
	administratorBrand: 0,
	tags: [],
	fundValue: 0,
};

export const fundStructureDefaultValue: StructureType = {
	fundOrigin: "",
	fundType: "",
	fundName: "",
	fundCodeName: "",
	jurisdiction: "",
	companyName: "",
	abn: "",
	acn: "",
	tfn: "",
	engagementUrlHash: "",
	lowValueFund: false,
};

export const fundMembersDefaultValue: Array<MemberType> = [];

export const fundBankDetailsDefaultValue: BankDetailsType = {
	applicationId: "",
	accountName: "",
	bsb: "",
	accountNumber: "",
};

export const fundintegrationDefaultValue: IntegrationType = {
	inClass: false,
	inEzidebit: false,
	classUrl: "",
	hubspotId: 0,
	hubspotUrl: "",
};

export const fundStatesDefaultValue: Array<StateType> = [];

export const trustDefaultValue: ITrust = {
	id: 0,
	trustName: "",
	trusteeCompanyName: "",
	trusteeCompanyAcn: "",
	trustType: "",
};

export const getFundDetailsDefaultValue: GetFundDetailsType = {
	account: fundAccountDefaultValue,
	fundStructure: fundStructureDefaultValue,
	members: fundMembersDefaultValue,
	bankDetails: fundBankDetailsDefaultValue,
	states: fundStatesDefaultValue,
	integration: fundintegrationDefaultValue,
	trust: [],
	statesStatus: {},
	isGetting: false,
	isSuccess: false,
	isError: false,
};

/**
 * Get fund details
 */

export type GetFundDetailsPayloadType = {
	id: number;
};

// Defines the shape of GetFundDetails action creator
export type GetFundDetails = {
	(payload: GetFundDetailsPayloadType): GetFundDetailsActionTypes;
};

type GetFundDetailsAction = {
	type: typeof actionTypes.GET_FUND_DETAILS | typeof actionTypes.GET_FUND_DETAILS_FOR_MODAL;
	payload: GetFundDetailsPayloadType;
};

type GetFundDetailsSuccessAction = {
	type: typeof actionTypes.GET_FUND_DETAILS_SUCCESS | typeof actionTypes.GET_FUND_DETAILS_FOR_MODAL_SUCCESS;
};

type GetFundDetailsFailureAction = {
	type: typeof actionTypes.GET_FUND_DETAILS_FAILURE | typeof actionTypes.GET_FUND_DETAILS_FOR_MODAL_FAILURE;
};

type GetFundDetailsCancelAction = {
	type: typeof actionTypes.GET_FUND_DETAILS_CANCEL;
};

type GetFundDetailsResetAction = {
	type: typeof actionTypes.GET_FUND_DETAILS_RESET | typeof actionTypes.GET_FUND_DETAILS_FOR_MODAL_RESET;
};

export type GetFundDetailsActionTypes =
	| GetFundDetailsSuccessAction
	| GetFundDetailsFailureAction
	| GetFundDetailsAction
	| GetFundDetailsResetAction
	| GetFundDetailsCancelAction;

/**
 * Create fund
 */

export type CreateFundType = {
	readonly isCreatingFund: boolean;
	readonly isCreatingFundSuccess: boolean;
	readonly isCreatingFundError: boolean;
};

export const createFundDefaultValue: CreateFundType = {
	isCreatingFund: false,
	isCreatingFundSuccess: false,
	isCreatingFundError: false,
};

export type CreateFund = {
	(): CreateFundType;
};

export type CreateFundPayloadType = {
	id: number;
	body: ObjectType;
};

type CreateFundAction = {
	type: typeof actionTypes.CREATE_FUND;
	payload: CreateFundPayloadType;
};

type CreateFundSuccessAction = {
	type: typeof actionTypes.CREATE_FUND_SUCCESS;
};

type CreateFundFailureAction = {
	type: typeof actionTypes.CREATE_FUND_FAILURE;
};

type CreateFundResetAction = {
	type: typeof actionTypes.CREATE_FUND_RESET;
};

export type CreateFundActionTypes =
	| CreateFundAction
	| CreateFundFailureAction
	| CreateFundResetAction
	| CreateFundSuccessAction;

/**
 * Set fund to resume
 */

export type SetFundToResumeType = {
	id: number;
};

export const setFundToResumeDefaultValue: SetFundToResumeType = {
	id: 0,
};

export type SetFundToResumePayloadType = {
	id: number;
};

export type SetFundToResumeCtr = {
	(payload: SetFundToResumePayloadType): SetFundToResumeType;
};

type SetFundToResumeAction = {
	type: typeof actionTypes.SET_FUND_TO_RESUME;
	payload: SetFundToResumePayloadType;
};

type SetFundToResumeResetAction = {
	type: typeof actionTypes.SET_FUND_TO_RESUME_RESET;
};

export type SetFundToResumeActionTypes = SetFundToResumeAction | SetFundToResumeResetAction;
