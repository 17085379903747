import { combineReducers } from "redux";
import * as actionTypes from "./actionTypes";

import {
	ArchiveFundActionTypes,
	ArchiveFundType,
	DeleteFundActionTypes,
	DeleteFundType,
	FavouriteFundActionTypes,
	FavouriteFundType,
	GetFundsActionTypes,
	GetFundsType,
	GetRequestSummaryActionTypes,
	GetRequestSummaryType,
	RestoreFundActionTypes,
	RestoreFundType,
	archiveFundDefaultValue,
	deleteFundDefaultValue,
	favouriteFundDefaultValue,
	getFundsDefaultValue,
	getRequestSummaryDefaultValue,
	restoreFundDefaultValue,
	getSMSFComplyingFundsCountDefaultValue,
	IGetSMSFComplyingFundsCount,
	IGetSMSFComplyingFundsCountActions,
	IGetFundsUserMeta,
	IGetFundsUserMetaActions,
	getFundsUserMetaDefaultValue,
	getTagsDefaultValue,
	IGetTagsActions,
	IGetTags,
	deactivateFundDefaultValue,
	DeactivateFundActionTypes,
	DeactivateFundType,
	downloadClientListDefaultValue,
	DownloadClientListActionTypes,
	DownloadClientListType,
} from "./types";

import { editTagsReducer } from "../FundPreviewPane/Account/Tags/AddRemoveTagsModal/__redux/reducers";
import { fundStatesReducer } from "../FundPreviewPane/StateProgress/__redux/reducers";

export const getFundsReducer = (state = getFundsDefaultValue, action: GetFundsActionTypes): GetFundsType => {
	switch (action.type) {
		case actionTypes.GET_FUNDS:
			return {
				...state,
				gettingFunds: true,
				isOnPoll: action.payload.isOnPoll,
			};
		case actionTypes.GET_FUNDS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				gettingFunds: false,
				getFundsFailure: false,
			};
		case actionTypes.GET_FUNDS_FAILURE:
			return {
				...state,
				gettingFunds: false,
				getFundsFailure: true,
			};
		case actionTypes.GET_FUNDS_CANCEL:
			return {
				...state,
				gettingFunds: false,
				getFundsFailure: false,
			};
		case actionTypes.GET_FUNDS_RESET:
			return {
				...state,
				...getFundsDefaultValue,
			};
		case actionTypes.GET_FUNDS_NOOP:
		default:
			return state;
	}
};

export const deleteFundReducer = (state = deleteFundDefaultValue, action: DeleteFundActionTypes): DeleteFundType => {
	switch (action.type) {
		case actionTypes.DELETE_FUND:
			return {
				...state,
				id: (action as any).payload.id,
				isDeleting: true,
			};
		case actionTypes.DELETE_FUND_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isDeleting: false,
				isDeleteSuccess: true,
				isDeleteError: false,
			};
		case actionTypes.DELETE_FUND_FAILURE:
			return {
				...state,
				isDeleting: false,
				isDeleteSuccess: false,
				isDeleteError: true,
			};
		case actionTypes.DELETE_FUND_RESET:
			return {
				...deleteFundDefaultValue,
			};
		default:
			return state;
	}
};

export const archiveFundReducer = (
	state = archiveFundDefaultValue,
	action: ArchiveFundActionTypes
): ArchiveFundType => {
	switch (action.type) {
		case actionTypes.ARCHIVE_FUND:
			return {
				...state,
				id: (action as any).payload.id,
				isArchiving: true,
			};
		case actionTypes.ARCHIVE_FUND_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isArchiving: false,
				isArchiveSuccess: true,
				isArchiveError: false,
			};
		case actionTypes.ARCHIVE_FUND_FAILURE:
			return {
				...state,
				isArchiving: false,
				isArchiveError: true,
			};

		case actionTypes.ARCHIVE_FUND_RESET:
			return {
				...archiveFundDefaultValue,
			};
		default:
			return state;
	}
};

export const restoreFundReducer = (
	state = restoreFundDefaultValue,
	action: RestoreFundActionTypes
): RestoreFundType => {
	switch (action.type) {
		case actionTypes.RESTORE_FUND:
			return {
				...state,
				id: (action as any).payload.id,
				isRestoring: true,
			};
		case actionTypes.RESTORE_FUND_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isRestoring: false,
				isRestoreSuccess: true,
				isRestoreError: false,
			};
		case actionTypes.RESTORE_FUND_FAILURE:
			return {
				...state,
				isRestoring: false,
				isRestoreError: true,
			};

		case actionTypes.RESTORE_FUND_RESET:
			return {
				...restoreFundDefaultValue,
			};
		default:
			return state;
	}
};

export const deactivateFundReducer = (
	state = deactivateFundDefaultValue,
	action: DeactivateFundActionTypes
): DeactivateFundType => {
	switch (action.type) {
		case actionTypes.DEACTIVATE_FUND:
			return {
				...state,
				id: (action as any).payload.id,
				isDeactivating: true,
			};
		case actionTypes.DEACTIVATE_FUND_SUCCESS:
			const { type: successType, ...successRest } = action;
			return {
				...state,
				...successRest,
				isDeactivating: false,
				isDeactivateSuccess: true,
				isDeactivateError: false,
			};
		case actionTypes.DEACTIVATE_FUND_FAILURE:
			return {
				...state,
				isDeactivating: false,
				isDeactivateSuccess: false,
				isDeactivateError: true,
			};

		case actionTypes.DEACTIVATE_FUND_RESET:
			return {
				...deactivateFundDefaultValue,
			};
		default:
			return state;
	}
};
export const favouriteFundReducer = (
	state = favouriteFundDefaultValue,
	action: FavouriteFundActionTypes
): FavouriteFundType => {
	switch (action.type) {
		case actionTypes.FAVORITE_FUND:
			return {
				...state,
				id: (action as any).payload.id,
			};
		case actionTypes.FAVORITE_FUND_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
			};
		case actionTypes.FAVORITE_FUND_FAILURE:
			return {
				...state,
				isError: true,
			};
		default:
			return state;
	}
};

export const getRequestSummaryReducer = (
	state = getRequestSummaryDefaultValue,
	action: GetRequestSummaryActionTypes
): GetRequestSummaryType => {
	switch (action.type) {
		case actionTypes.GET_REQUEST_SUMMARY:
			return {
				...state,
				isGettingRequestSummary: true,
			};
		case actionTypes.GET_REQUEST_SUMMARY_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
			};
		case actionTypes.GET_REQUEST_SUMMARY_FAILURE:
			return {
				...state,
				isGettingRequestSummary: false,
				isGetRequestSummaryError: true,
			};
		case actionTypes.GET_REQUEST_SUMMARY_CANCEL:
		case actionTypes.GET_REQUEST_SUMMARY_RESET:
			return {
				...state,
				...getFundsDefaultValue,
			};
		default:
			return state;
	}
};

export const getSMSFComplyingFundsCountReducer = (
	state = getSMSFComplyingFundsCountDefaultValue,
	action: IGetSMSFComplyingFundsCountActions
): IGetSMSFComplyingFundsCount => {
	switch (action.type) {
		case actionTypes.GET_SMSF_COMPLYING_FUNDS_COUNT:
			return {
				...state,
				gettingSMFComplyingFundsCount: true,
			};
		case actionTypes.GET_SMSF_COMPLYING_FUNDS_COUNT_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				gettingSMFComplyingFundsCount: false,
				getSMSFComplyingFundsCountSuccess: true,
				getSMSFComplyingFundsCountFailure: false,
			};
		case actionTypes.GET_SMSF_COMPLYING_FUNDS_COUNT_FAILURE:
			return {
				...state,
				gettingSMFComplyingFundsCount: false,
				getSMSFComplyingFundsCountSuccess: false,
				getSMSFComplyingFundsCountFailure: true,
			};
		default:
			return state;
	}
};

export const getFundsUserMetaReducer = (
	state = getFundsUserMetaDefaultValue,
	action: IGetFundsUserMetaActions
): IGetFundsUserMeta => {
	switch (action.type) {
		case actionTypes.GET_FUND_USER_META:
			return {
				...state,
				gettingFundsUserMeta: true,
			};
		case actionTypes.GET_FUND_USER_META_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				gettingFundsUserMeta: false,
				getFundsUserMetaSuccess: true,
				getFundsUserMetaFailure: false,
			};
		case actionTypes.GET_FUND_USER_META_FAILURE:
			return {
				...state,
				gettingFundsUserMeta: false,
				getFundsUserMetaSuccess: false,
				getFundsUserMetaFailure: true,
			};
		default:
			return state;
	}
};

export const getTagsReducer = (state = getTagsDefaultValue, action: IGetTagsActions): IGetTags => {
	switch (action.type) {
		case actionTypes.GET_TAGS:
			return {
				...state,
				gettingTags: true,
			};
		case actionTypes.GET_TAGS_SUCCESS:
			const { type, ...rest } = action;
			return {
				...state,
				...rest,
				gettingTags: false,
				getTagsSuccess: true,
				getTagsFailure: false,
			};
		case actionTypes.GET_TAGS_FAILURE:
			return {
				...state,
				gettingTags: false,
				getTagsSuccess: false,
				getTagsFailure: true,
			};
		case actionTypes.GET_TAGS_RESET:
			return {
				...getTagsDefaultValue,
			};
		default:
			return state;
	}
};

export const downloadClientListReducer = (
	state = downloadClientListDefaultValue,
	action: DownloadClientListActionTypes
): DownloadClientListType => {
	switch (action.type) {
		case actionTypes.DOWNLOAD_CLIENT_LIST:
			return {
				...state,
				isDownloadingClientList: true,
			};
		case actionTypes.DOWNLOAD_CLIENT_LIST_SUCCESS:
			return {
				...state,
				isDownloadingClientList: false,
				isDownloadClientListSuccess: true,
				isDownloadClientListError: false,
			};
		case actionTypes.DOWNLOAD_CLIENT_LIST_FAILURE:
			return {
				...state,
				isDownloadingClientList: false,
				isDownloadClientListSuccess: false,
				isDownloadClientListError: true,
			};
		case actionTypes.DOWNLOAD_CLIENT_LIST_RESET:
			return {
				...state,
				...downloadClientListDefaultValue,
			};
		default:
			return state;
	}
};

export const fundsReducer = combineReducers({
	getFunds: getFundsReducer,
	deleteFund: deleteFundReducer,
	archiveFund: archiveFundReducer,
	restoreFund: restoreFundReducer,
	deactivateFund: deactivateFundReducer,
	favouriteFund: favouriteFundReducer,
	editTags: editTagsReducer,
	fundStates: fundStatesReducer,
	getRequestSummary: getRequestSummaryReducer,
	getSMSFComplyingFundsCount: getSMSFComplyingFundsCountReducer,
	getFundsUserMeta: getFundsUserMetaReducer,
	getTags: getTagsReducer,
	downloadClientList: downloadClientListReducer,
});
