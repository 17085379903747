// Create Payment
export const ADD_PAYMENT = "ADD_PAYMENT";
export const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS";
export const ADD_PAYMENT_FAILURE = "ADD_PAYMENT_FAILURE";
export const ADD_PAYMENT_RESET = "ADD_PAYMENT_RESET";

export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_FAILURE = "UPDATE_PAYMENT_FAILURE";
export const UPDATE_PAYMENT_RESET = "UPDATE_PAYMENT_RESET";
