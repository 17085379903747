import { LogoutOutlined } from "@ant-design/icons";
import Menu from "antd/es/menu";
import cx from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { userProfileSlr } from "__pages/User/__redux/selectors";
import { UserProfileType } from "__pages/User/__redux/types";
import { BREAKPOINTS } from "__utils/constants";
import { errorHandler, getAxiosInstance } from "__utils/helpers";
import { getReminderCountSlr } from "__pages/ManageReminders/__redux/selectors";
import { IGetReminderCount } from "__pages/ManageReminders/__redux/types";
import DesktopHeader from "./HeaderLayout/DesktopHeader";
import MobileHeader from "./HeaderLayout/MobileHeader";
import { GlobalStyle, HeaderStyled } from "./style";
import { setHeaderPreviewSlr } from "./__redux/selectors";
import { SetHeaderPreviewType } from "./__redux/types";

type Props = unknown;

const AppHeader: React.FC<Props> = () => {
	const userProfile: UserProfileType = useSelector(userProfileSlr);

	const isXS: boolean = useMediaQuery({ query: BREAKPOINTS.XS });
	const { darkMode } = userProfile;
	const headerPreview = useSelector(setHeaderPreviewSlr);
	const { darkMode: previewDarkMode }: SetHeaderPreviewType = headerPreview;
	const { reminderCount }: IGetReminderCount = useSelector(getReminderCountSlr);

	const onSignOut = (): void => {
		const axios = getAxiosInstance(process.env.REACT_APP_APP_URL as string);
		axios
			.get("/sign-out")
			.then(() => {
				window.location.assign(process.env.REACT_APP_APP_URL as string);
			})
			.catch((e: any) => {
				errorHandler(e);
			});
	};

	const userMenu = (
		<Menu className="session-dropdown-menu">
			<Menu.Item onClick={onSignOut} key="sign-out">
				<LogoutOutlined />
				Logout
			</Menu.Item>
		</Menu>
	);

	const requestDropMenu = (
		<Menu className="dropdown-menu">
			<Menu.Item key="1">
				<Link to="/request-documents">Documents</Link>
			</Menu.Item>
			<Menu.Item key="2">
				<Link to="/request-templates">Templates</Link>
			</Menu.Item>
			<Menu.Item key="3">
				<Link to="/manage-reminders">Review Reminders</Link>
			</Menu.Item>
		</Menu>
	);

	const workflowDropMenu = (
		<Menu className="dropdown-menu">
			<Menu.Item key="1">
				<Link to="/workflow-dashboard">Dashboard</Link>
			</Menu.Item>
			<Menu.Item key="2">
				<Link to="/workflow-chart">Chart</Link>
			</Menu.Item>
		</Menu>
	);

	return (
		<HeaderStyled className={cx({ "dark-mode": previewDarkMode || darkMode })}>
			<GlobalStyle />
			{isXS ? (
				<MobileHeader
					requestDropMenu={requestDropMenu}
					workflowDropMenu={workflowDropMenu}
					userMenu={userMenu}
					headerPreview={headerPreview}
					userProfile={userProfile}
					reminderCount={reminderCount}
				/>
			) : (
				<DesktopHeader
					requestDropMenu={requestDropMenu}
					workflowDropMenu={workflowDropMenu}
					userMenu={userMenu}
					headerPreview={headerPreview}
					userProfile={userProfile}
					reminderCount={reminderCount}
				/>
			)}
		</HeaderStyled>
	);
};

export default React.memo(AppHeader);
