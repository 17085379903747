export const FUNDS = "/fund/";
export const FAVOURITE_FUND = "/fund/toggle_favourite/";
export const GET_REQUEST_SUMMARY = "/admin_requests/summary/";
export const ADD_TO_WORKFLOW = "";
export const GET_WORKFLOW_SUMMARY = "/workflows/summary/";
export const GET_WORKFLOW_UPDATE = "/fund/poll_state/:memberId/";
export const GET_SMSF_COMPLYING_FUNDS_COUNT = "/fund/smsf_complying/";
export const GET_FUND_USER_META = "/fund/filters/";
export const GET_TAGS = "/fund/tags/";
export const DEACTIVATE_ACCOUNT = "/fund/:id/deactivate/";
export const RESTORE_ACCOUNT = "/fund/:id/restore/";
export const ARCHIVE_ACCOUNT = "/fund/:id/archive/";
export const DELETE_ACCOUNT = "/fund/:id/delete/";
export const DOWNLOAD_CLIENT_LIST = "/fund/client_list/";
